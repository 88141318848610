import React from "react";
import Login from "views/login/Login.jsx";
import ResetPassword from "./views/user/ResetPassword";
import UserAddUpdate from "./views/user/UserAddUpdate";
import OfferAddUpdate from "./views/offer/OfferAddUpdate";
import RequestList from "./views/request/RequestList";
import RequestAddUpdate from "./views/request/RequestAddUpdate";
import ViewRequest from "./views/request/ViewRequest";
import Register from "./views/login/Register";
import PaymentList from "./views/payment/PaymentList";
import ViewOffer from "./views/offer/ViewOffer";
import ChangePassword from "./views/general/ChangePassword";
import ForgotPassword from "./views/login/ForgotPassword";
import ViewProject from "./views/project/ViewProject";
import RequestAward from "./views/request/RequestAward";
import OfferSend from "./views/offer/OfferSend";




const AssignedRequestList = (props) => {
    return (
        <RequestList
            pathname={"request"}
            pathlink={"/assigned"}
            makeoffer={true}
            retractdeleterequest={true}
            showofferdeadline={true}
            emptymsg={"You have no assigned requests now. But don't worry, stay with us as we have exciting projects coming up!"}
            {...props}
        />
    );
}

const PendingOfferList = (props) => {
    return (
        <RequestList
            pathname={"offer"}
            pathlink={"/pending"}
            viewoffer={true}
            editoffer={true}
            retract={true}
            emptymsg={"You have no pending offer."}
            {...props}
        />
    );
}

const ArchivedOfferList = (props) => {
    return (
        <RequestList
            pathname={"offer"}
            pathlink={"/archived"}
            viewoffer={true}
            emptymsg={"You have no archived offer."}
            {...props}
        />
    );
}

const OpenRequestList = (props) => {
    return (
        <RequestList
            pathname={"request"}
            pathlink={"/open"}
            viewrequest={true}
            cancelrequest={true}
            detectoffercount={true}
            emptyrequestlink={true}
            emptymsg={""}
            {...props}
        />
    );
}
const ArchivedRequestList = (props) => {
    return (
        <RequestList
            pathname={"request"}
            pathlink={"/archived"}
            viewrequest={true}
            detectoffercount={true}
            emptymsg={"You have no archived request."}
            {...props}
        />
    );
}


const ActiveProjectList = (props) => {
    return (
        <RequestList
            pathname={"project"}
            pathlink={"/active"}
            isproject={true}
            viewproject={true}
            emptymsg={"You have no active project."}
            {...props}
        />
    );
}

const ArchivedProjectList = (props) => {
    return (
        <RequestList
            pathname={"project"}
            pathlink={"/archived"}
            isproject={true}
            viewproject={true}
            emptymsg={"You have no archived project."}
            {...props}
        />
    );
}

const routes = [
    {
        path: "/client",
        name: "Requests",
        classItem:"menuItem",
        allow: ["client"],
        icon: "nc-icon nc-shop",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse0",
        views: [

            {
                path: "/request/view/:id",
                name: "view request",
                menu: false,
                icon: "nc-icon nc-settings-gear-65",
                component: ViewRequest,
                layout: "/admin"
            },
            {
                path: "/request/reward/:id",
                name: "Award Offer",
                menu: false,
                icon: "nc-icon nc-settings-gear-65",
                component: RequestAward,
                layout: "/admin"
            },


            {
                path: "/project/view/:id",
                name: "view project",
                menu: false,
                icon: "nc-icon nc-settings-gear-65",
                component: ViewProject,
                layout: "/admin"
            },
            {
                path: "/request/edit/:id",
                name: "edit request",
                menu: false,
                icon: "nc-icon nc-settings-gear-65",
                component: RequestAddUpdate,
                layout: "/admin"
            },
            {
                path: "/request/new",
                name: "New Requests",
               // icon: "nc-icon nc-shop",
                menu: true,
                id:"newrequest",
                //mini :"N",
                allow : ["client"],
                classItem:"menuItem simxxx",
                component: RequestAddUpdate,
                layout: "/admin"
            },
            {
                path: "/request/list",
                name: "Open Requests",
                menu: true,
                allow : ["client"],
                component: OpenRequestList,
                layout: "/admin"
            },
            {
                path: "/request/archived",
                name: "Archived Requests",
                menu: true,
                allow : ["client"],
                component: ArchivedRequestList,
                layout: "/admin"
            },

        ]
    },
    {
        path: "/expert",
        name: "Requests & Offers",
        classItem:"menuItem",
        allow: ["expert"],
        icon: "nc-icon nc-shop",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse0",
        views: [

            {
                path: "/request/assigned",
                name: "Assigned Requests",
                menu: true,
                classItem:"menuItem",
                component: AssignedRequestList,
                layout: "/admin"
            },
            {
                path: "/offer/edit/:id",
                name: "edit offer",
                menu: false,
                icon: "nc-icon nc-settings-gear-65",
                component: OfferAddUpdate,
                layout: "/admin"
            },
            {
                path: "/offer/new/:requestid",
                name: "New offer",
                menu: true,
                allow : ["client"],
                classItem:"menuItem",
                component: OfferAddUpdate,
                layout: "/admin"
            },

            {
                path: "/offer/send/:id",
                name: "Offer Payment",
                menu: true,
                allow : ["client"],
                classItem:"menuItem",
                component: OfferSend,
                layout: "/admin"
            },
            {
                path: "/offer/view/:id",
                name: "View offer",
                menu: true,
                allow : ["client"],
                classItem:"menuItem",
                component: ViewOffer,
                layout: "/admin"
            },


            {
                path: "/offer/pending",
                name: "Open Offers",
                menu: true,
                component: PendingOfferList,
                layout: "/admin"
            },
            {
                path: "/offer/archived",
                name: "Archived Offers",
                menu: true,
                component: ArchivedOfferList,
                layout: "/admin"
            },

        ]
    },

    {
        path: "/projects",
        name: "Projects",
        classItem:"menuItem",
        allow: ["client","expert"],
        icon: "nc-icon nc-money-coins",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse1",
        views: [


            {
                path: "/assignedproject",
                name: "Assigned Projects",
                menu: true,
                allow : ["expert"],
                //icon: "nc-icon nc-money-coins",
                component: ActiveProjectList,
                layout: "/admin"
            },
            {
                path: "/activeprojects",
                name: "Active Projects",
                menu: true,
                allow : ["client"],
                component: ActiveProjectList,
                layout: "/admin"
            },
            {
                path: "/archivedprojects",
                name: "Archived Projects",
                menu: true,
                allow : ["client","expert"],
                component: ArchivedProjectList,
                layout: "/admin"
            },

        ]
    },

    {
        path: "/blog",
        name: "Payments",
        classItem:"menuItem",
        allow: ["client","expert"],
        icon: "nc-icon nc-paper",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse2",
        views: [
            {
                path: "/articlecategory",
                name: "Payments History",
                menu: true,
                allow : ["client","expert"],
                //icon: "nc-icon nc-paper",
                component: PaymentList,
                layout: "/admin"
            },

        ]
    },







    {
        path: "/resetpassword/:hash",
        name: "resetpassword",
        menu: false,
        component: ChangePassword,
        layout: "/auth"
    },
    {
        path: "/forgotpassword",
        name: "forgotpassword",
        menu: false,
        component: ForgotPassword,
        layout: "/auth"
    },


    {
        path: "/login",
        name: "Login",
        menu: false,
        component: Login,
        layout: "/auth"
    },
    {
        path: "/register",
        name: "register",
        menu: false,
        component: Register,
        layout: "/auth"
    },






    {
        path: "/myprofile",
        name: "User Profile",
        menu: false,
        component: UserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/reset",
        name: "reset password ",
        menu: false,
        component: ResetPassword,
        layout: "/admin"
    },




];



export default routes;
