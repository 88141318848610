import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Label,
    Col, Button
} from "reactstrap";
import {categorylist} from "../../core/staticdata";


import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import SelectRow from "../../components/Select/SelectRow";


import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import FileUpload from "../../components/file-upload/file-upload.component";
import {Utilites} from "../../core/Utilites";
import {selectCountryList} from "../../core/countries";

export default class RequestAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);


        this.state = {
            isLoading: false,
            title:"",
            outcome:"",
            expertise:"",
            randdproducts:"",
            tradeprojectother:"",
            budget:"",
            deadline:"",
            duration:"",
            comments:"",
            category:"",
            othercategory:"",
            othercategorydescription:"",
            randdprojectstatusother:"",
            certificationexpectedother:"",
            otherranddtype:"",
            othertraderequest:"",
            currency:"USD",
            _id:"",
            loading : false,
            attachements:[],
            attachments_url:[],
            startdate:"",
            randdtype:"",
        randdprojectstatus:"",
        randdlevel:"",
        randdchallenge:"",
        fsmstype:"",
        fsmslevel:"",
        fsmschallenge:"",
        fsmsmeasure:"",
        fsmsdoc:"",
        certificationtype:"",
        certificationlevel:"",
        certificationchallenge:"",
        certificationmeasure:"",
        certificationdoc:"",
        certificationexpected:"",
        tradetype:"",
        tradecountry:[],
        tradetime:"",
        traderequest:"",
        tradeactivities:"",
        tradeproject:"",
        trainingtype:"",
        trainingtopic:"",
        trainingnumber:"",
        trainingwork:"",
        traininglevel:"",
        trainingpreivous:"",
        trainingtooltip:"",
        trainingtimeline:"",
        trainingdaytime:"",
        traininglocation:"",
            traininglocationother:"",

            randdchallengeyes:"",
            fsmsdocyes:"",
            fsmschallengeyes:"",
            certificationchallengeyes:"",
            certificationdocyes:"",
            trainingpreivousyes:""
        };
        this.pathname = "request";
        this.engine = new RequestEngine();

        this.idFileBase64Attach = [];
    }


    componentDidMount() {
        const edit = this.props.location.data

        if (edit){


            edit.attachments_url.map(i=>{
                this.idFileBase64Attach.push(i);
            })

            this.setState({
                ...edit
            });
        }
    }



    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })


        let data = {...this.state}

        data.attachements = this.idFileBase64Attach;
        const response = await this.engine.saveItem(this.pathname,data)
        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname+"/list");
        }
        this.setState({
            loading: false
        })

    };

    updateUploadedFiles(files){
        this.idFileBase64Attach = [];
        files.map(i=>{
            if(!i.url){
                Utilites.getFileBase64(i, (result) => {
                    this.idFileBase64Attach.push({base64:result,name:i.name});
                });
            }else{
                this.idFileBase64Attach.push(i);
            }
        })
    }


    renderRandD() {

        const {
            randdchallengeyes,
            otherranddtype,
            randdproducts,
        randdtype,
            randdprojectstatusother,
        randdprojectstatus,
        randdlevel,
        randdchallenge}= this.state;
        return (<div>
            <Row>
                <Col md={"6"} >
                    <SelectRow label="Type of optimization requested"  required={true}   name="randdtype"  className={"mr-2"} defaultValue={randdtype}  data={this.createSelectValue(["shelf life/quality/packaging optimization of existing product","new product development ","process line optimization ","trials","Other"])} changeInput={this.changeInput.bind(this)} />
                    {randdtype=="Other" && <FormRow label="Please sepcify"  required={true} name="otherranddtype" data={otherranddtype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                    <FormRow label="Products"  required={true} name="randdproducts" data={randdproducts} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>

                <Col md="6" >
                    <SelectRow label="Project status "  required={true}   name="randdprojectstatus"  className={"mr-2"} defaultValue={randdprojectstatus}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the research/trials/optimization "])} changeInput={this.changeInput.bind(this)} />
                    {randdprojectstatus.includes("started") && <FormRow required={true} label="Please specify what activities have you implemented"  name="randdprojectstatusother" data={randdprojectstatusother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                </Col>
            </Row>
            <Row>
                <Col md="6" >
                    <FormRow label="Description of recipes or process steps & conditions" required={true} type={"textarea"} name="randdlevel" data={randdlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="6" >
                    <SelectRow label="Challenge/issue/problem faced"  required={true}   name="randdchallenge"  className={"mr-2"} defaultValue={randdchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {randdchallenge=="yes" && <FormRow label="Please Write it here" required={true} name="randdchallengeyes" data={randdchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                </Col>
            </Row>
        </div>)

    }
    renderFSMS() {

        const {
            fsmsdocyes,
            fsmschallengeyes,
            fsmstype,
            fsmslevel,
            fsmschallenge,
            fsmsmeasure,
            fsmsdoc,}= this.state;
        return (<div>
            <Row>
                <Col md="6" >
                    <FormRow label="Type of service"  required={true} name="fsmstype" data={fsmstype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="6" >
                    <FormRow label="High level process steps & conditions surrounding the production line" required={true}  type={"textarea"} name="fsmslevel" data={fsmslevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="6" >
                    <SelectRow label="Challenge/issue/problem faced"  required={true}   name="fsmschallenge"  className={"mr-2"} defaultValue={fsmschallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {fsmschallenge=="yes" && <FormRow label="Please Write it here" required={true} name="fsmschallengeyes" data={fsmschallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                    <FormRow label="Current measures taken" required={true} name="fsmsmeasure" data={fsmsmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="6" >
                    <SelectRow label="Documentation in place"  required={true}   name="fsmsdoc"  className={"mr-2"} defaultValue={fsmsdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {fsmsdoc=="yes" && <FormRow label="Please Write it here" required={true} name="fsmsdocyes" data={fsmsdocyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                </Col>
            </Row>
        </div>)

    }
    renderCERTIFICATION() {

        const {
            certificationdocyes,
            certificationchallengeyes,
            certificationtype,
            certificationlevel,
            certificationchallenge,
            certificationexpectedother,
            certificationmeasure,
            certificationdoc,
            certificationexpected,}= this.state;
        return (<div>
            <Row>
                <Col md="4" >
                    <SelectRow label="Type of service"  required={true}   name="certificationtype"  className={"mr-2"} defaultValue={certificationtype}  data={this.createSelectValue(["gap analysis"," pre-certification","pre-audit "])} changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <FormRow label="Description of recipes or process steps & conditions" required={true}  type={"textarea"} name="certificationlevel" data={certificationlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >

                    <SelectRow label="Challenge/issue/problem faced"  required={true}   name="certificationchallenge"  className={"mr-2"} defaultValue={certificationchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {certificationchallenge=="yes" && <FormRow label="Please Write it here" required={true} name="certificationchallengeyes" data={certificationchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                </Col>
            </Row>
            <Row>
                <Col md="4" >
                    <FormRow label="Current measures taken"  required={true} name="certificationmeasure" data={certificationmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <SelectRow label="Documentation in place"  required={true}   name="certificationdoc"  className={"mr-2"} defaultValue={certificationdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {certificationdoc=="yes" && <FormRow label="Please Write it here" required={true} name="certificationdocyes" data={certificationdocyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                 </Col>
                <Col md="4" >
                    <SelectRow label="Certification expected "  required={true}   name="certificationexpected"  className={"mr-2"} defaultValue={certificationexpected}  data={this.createSelectValue(["HACCP","ISO22000","FSSC22000","ISO9001","BRC","Global GAP"," Organic certification","Other"])} changeInput={this.changeInput.bind(this)} />

                    {certificationexpected=="Other" &&
                    <FormRow label="Please sepcify"  name="certificationexpectedother" required={true} data={certificationexpectedother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }
                </Col>
            </Row>
        </div>)

    }
    renderTrade() {

        const {
            othertraderequest,
            tradetype,
            tradecountry,
            tradetime,
            traderequest,
            tradeactivities,
            tradeproject,tradeprojectother}= this.state;
        return (<div>
            <Row>
                <Col md="4" >
                    <SelectRow label="Type of service"  required={true}   name="tradetype"  className={"mr-2"} defaultValue={tradetype}  data={this.createSelectValue(["import","export"])} changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <SelectRow label="Country" isMulti={true}  required={true}   name="tradecountry"  className={"mr-2"} defaultValue={tradecountry}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <SelectRow label="Time in trade"  required={true}   name="tradetime"  className={"mr-2"} defaultValue={tradetime}  data={this.createSelectValue(["1st time importing / exporting","new import /export activities or products"])} changeInput={this.changeInput.bind(this)} />
                </Col>

            </Row>

            <Row>
                <Col md="4" >
                    <SelectRow label="Request"  required={true}   name="traderequest"  className={"mr-2"} defaultValue={traderequest}  data={this.createSelectValue(["food safety & quality regulations compliance (FDA, EU, CFIA, GSO..."," label creation","label review","packaging compliance","Other"])} changeInput={this.changeInput.bind(this)} />
                    {traderequest=="Other" &&
                        <FormRow label="Please sepcify"  required={true} name="othertraderequest" data={othertraderequest} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }
                </Col>
                <Col md="4" >
                   <FormRow label="Activities/Products"  required={true} name="tradeactivities" data={tradeactivities} className={"mr-2"} type={"textarea"}    changeInput={this.changeInput.bind(this)} />


                </Col>
                <Col md="4" >

                    <SelectRow label="Project status"  required={true}   name="tradeproject"  className={"mr-2"} defaultValue={tradeproject}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the compliance/labelling/packaging"])} changeInput={this.changeInput.bind(this)} />

                    {tradeproject.includes("started") &&
                    <FormRow label="Please specify what activities have you implemented" required={true}  name="tradeprojectother" data={tradeprojectother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }
                </Col>
            </Row>

        </div>)

    }
    renderTraining() {

        const {
            trainingpreivousyes,
            trainingtype,
            trainingtopic,
            trainingnumber,
            trainingwork,
            traininglevel,
            trainingpreivous,
            trainingtooltip,
            trainingtimeline,
            trainingdaytime,
            traininglocation,traininglocationother}= this.state;
        return (<div>
            <Row>
                <Col md="4" >
                    <SelectRow label="Type of training"  required={true}   name="trainingtype"  className={"mr-2"} defaultValue={trainingtype}  data={this.createSelectValue(["Individual","company","cluster/group"])} changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <FormRow label="Topic of training"  required={true} name="trainingtopic" data={trainingtopic} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                 </Col>
                <Col md="4" >
                    <FormRow label="Number of trainees" type={"number"}  required={true} name="trainingnumber" data={trainingnumber} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>




            </Row>

            <Row>
                <Col md="4" >
                    <FormRow label="Type of work the trainees perform" required={true} name="trainingwork" data={trainingwork} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <FormRow label="Level of knowledge of the trainees (are they new at work ?)" required={true} name="traininglevel" data={traininglevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="4" >
                    <SelectRow label="Previous training received"  required={true}   name="trainingpreivous"  className={"mr-2"} defaultValue={trainingpreivous}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {trainingpreivous=="yes" && <FormRow label="Please Write it here" required={true}  name="trainingpreivousyes" data={trainingpreivousyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}
                </Col>




            </Row>

            <Row>
                <Col md="3" >
                    <FormRow label="Specific consideration"  name="trainingtooltip" required={true} data={trainingtooltip} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="3" >
                    <SelectRow label="Timeline"  required={true}   name="trainingtimeline"  className={"mr-2"} defaultValue={trainingtimeline}  data={this.createSelectValue(["one-time training","recurrent training "])} changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="3" >
                    <SelectRow label="day time"  required={true}   name="trainingdaytime"  className={"mr-2"} defaultValue={trainingdaytime}  data={this.createSelectValue(["am","pm"])} changeInput={this.changeInput.bind(this)} />
                </Col>


                <Col md="3" >
                    <SelectRow label="Location to conduct the training"  required={true}   name="traininglocation"  className={"mr-2"} defaultValue={traininglocation}  data={this.createSelectValue(["online","in person"])} changeInput={this.changeInput.bind(this)} />
                    {traininglocation=="in person" && <FormRow required={true} label="Please specify the location"  name="traininglocationother" data={traininglocationother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                </Col>

            </Row>
        </div>)

    }
    render() {
        const {loading,isLoading, title,
            expertise,
            budget,
            duration,
            comments,

            othercategory,
            othercategorydescription,
            outcome,
            category,attachments_url,currency,deadline ,startdate}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card style={{    padding: 20}}>
                                    <CardHeader>
                                        <CardTitle tag="h4" >Request A Service</CardTitle>
                                        <CardTitle tag="h6" style={{marginTop:10}} >Fill out all your project details and we will get back to you with the best offer and the expert that matches your requirements.</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <Row>
                                            <Col md="6" >
                                               <FormRow label="Title" info={"Choose a descriptive title as it will be used as the main reference for your project."} name="title" data={title} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                                                {category=="Other" &&
                                               <div style={{marginTop:14}}><FormRow label="Project Description"  name="othercategorydescription" data={othercategorydescription} className={"mr-2"}    changeInput={this.changeInput.bind(this)} /></div>
                                                }
                                            </Col>
                                            <Col md={"6"} >
                                                <SelectRow label="Category"  className={"mr-2"} info={"Choose any category that most closely resembles your request/project, and if it fits multiple or not, choose the closest option and explain the rest in the text field below."} name="category"  defaultValue={category}  data={this.createSelectValue(categorylist)} changeInput={this.changeInput.bind(this)} />
                                                {category=="Other" &&
                                                <FormRow label="Please sepcify"  name="othercategory" data={othercategory} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                                                }
                                            </Col>


                                        </Row>

                                        {category.includes("search") && this.renderRandD()}
                                        {category.includes("ocumentation") && this.renderFSMS()}
                                        {category.includes("ertification") && this.renderCERTIFICATION()}
                                        {category.includes("ssistance") && this.renderTrade()}
                                        {category.includes("aining") && this.renderTraining()}


                                        <Row>
                                            <Col md="12" >
                                                <SelectRow label="Expertise" info={"What expertise level suits you"}  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(["Fresh Graduate","Junior (3-5 years of experience)","Senior (5-10 years of experience)","Managerial (10+ years of experience)"])} changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="3" >
                                                <FormRow label={"Budget ("+currency+")"} className={"mr-2"}   type={"number"} name="budget" data={budget}    changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="2" >
                                                <SelectRow label="Currency"  required={true}  className={"mr-2"} name="currency"  defaultValue={currency}  data={this.createSelectValue(["USD","LBP"])} changeInput={this.changeInput.bind(this)} />
                                            </Col>

                                            <Col md="2" >
                                                <FormRow label="Duration (Days)" className={"mr-2"} type={"number"} name="duration" data={duration}    changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="2" >
                                                <FormRow label="Start date" type={"date"} className={"mr-2"}  name="startdate" data={startdate}    changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="3" >
                                                <FormRow label="End Date" type={"date"} name="deadline" data={deadline}    changeInput={this.changeInput.bind(this)} />
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md="12" >
                                                <FormRow label="Outcome / Result Expected" required={false} type={"textarea"} name="outcome" data={outcome}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" >
                                                <FormRow label="Additional Description or Comments" required={false} type={"textarea"} name="comments" data={comments}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>




                                        <Row>
                                            <Col sm="12">
                                                <FileUpload
                                                    accept=".jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx"
                                                    index={0}
                                                    label="If you have any project related documents that would help us better determine the scope of work, please attach them."
                                                    multiple
                                                    updateFilesCb={this.updateUploadedFiles.bind(this)}
                                                    oldvalue={attachments_url}
                                                />
                                            </Col>
                                        </Row>

                                    </CardBody>

                                    <CardFooter >
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Cancel</Button>
                                        <ButtonLoader className="pull-right" color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


