import axios from "axios/index";
import Constants from "../core/Constants";

 class RequestEngine {
    constructor() {
        let token = localStorage.getItem('token');
        this.apiEngine = axios.create({
            baseURL: Constants.serverlink,
            timeout: Constants.timeout,
            headers: {
                'Content-Type': 'application/json',
                'token':token
            }
        });
       // this.debugit();
    }


    debugit(){
        this.apiEngine.interceptors.request.use(request => {
            console.log('Starting Request', request);
            return request
        });

        this.apiEngine.interceptors.response.use(response => {
            console.log('Response:', response);
            return response
        })
    }




     saveSettings(data,callback) {
         const link = '/api/settings/save';
         console.warn(link);
         this.apiEngine.post(link,data)
             .then((response) => {
                 callback(response);
             })
             .catch((error) => {
                 console.log(error);
             });
     }




     async deleteItem(path,id) {
         const link = '/api/'+path+'/delete/'+id;
         console.log(link)
         return await this.apiEngine.get(link)
     }



     async login(email,password,saml) {
         const link = '/api/user/login';
         const data = {email :email,password:password,saml:saml}
         console.warn(link);
         return await this.apiEngine.post(link,data);
     }
     async resendlink(email,password) {
         const link = '/api/user/resendlink';
         const data = {email :email,password:password}
         console.warn(link);
         return await this.apiEngine.post(link,data);
     }
     async register(data) {
         const link = '/api/user/create';
         console.warn(link);
         return await this.apiEngine.post(link,data);
     }


     getSettingsinfo(callback) {
         const link = '/api/settings';
         console.warn(link);
         this.apiEngine.get(link)
             .then((response) => {
                 callback(response);
             })
             .catch((error) => {
                 console.log(error);
             });
     }


     getdashboard(callback) {
         const link = '/api/dashboard';
         console.warn(link);
         this.apiEngine.get(link)
             .then((response) => {
                 callback(response);
             })
             .catch((error) => {
                 console.log(error);
             });
     }






     saveUser(formData,callback) {
         const link = '/api/user/save';
         console.warn(link);
         const config = {
             headers: {
                 'content-type': 'multipart/form-data'
             }
         };
         this.apiEngine.post(link,formData,config)
             .then((response) => {
                 callback(response);
             })
             .catch((error) => {
                 console.log(error);
             });
     }

     async saveItemData(path,formData) {
         const link = '/api/'+path+'/save';

         const config = {
             headers: {
                 'content-type': 'multipart/form-data'
             }
         };
         return await  this.apiEngine.post(link,formData,config)
     }
     async saveItem(path,data) {
         const link = '/api/'+path+'/save';
         console.warn(link);
         return await this.apiEngine.post(link,data);
     }

     async getItem(path,param = "/list") {
         const link = '/api/'+path+param;
         console.warn(link);
         return await this.apiEngine.get(link)
     }

     async exportCSV(path,param = "/list") {
         const link = Constants.serverlink+'api/admin/'+path+param;
         axios({
             url: link, //your url
             method: 'GET',
             responseType: 'blob', // important
         }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'export.csv'); //or any other extension
             document.body.appendChild(link);
             link.click();
         });
     }


     async postItem(path,param = "/list",data = {}) {
         const link = '/api/'+path+param;
         console.warn(link);

         return await this.apiEngine.post(link,data)
     }

}

export default RequestEngine;
