import React from "react";

// reactstrap misc
import {
    CardBody,
    Container,
    Col,
    Row, Card,
    FormGroup,
    Label,
    Tooltip
} from "reactstrap";
import { isValidPhoneNumber } from 'react-phone-number-input'
import startsWith from 'lodash.startswith';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";
import {employmentstatuslist,sectoroperatorin,titlelist,educationlist,expertiselist} from "../../core/staticdata";

import {selectCountryList} from '../../core/countries';
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import {AvForm} from "availity-reactstrap-validation";
import FormRow from "../../components/Row/FormRow";
import SelectRow from "../../components/Select/SelectRow";
import {Link} from "react-router-dom";
import {Utilites} from "../../core/Utilites";

class Register extends CoreEngine  {

    constructor(props) {
        super(props);

        this.state = {
            title:"",
            first:"",
            last:"",
            email:"",
            password:"",
            birthday:"",
            gender:"",
            tooltipOpen:false,
            tooltipOpen2:false,
            major:"",
            company:"",
            employmentstatus:"",
            workphonerror:"",
            country:"",
            interestedin:"",
            educational:"",
            phone:"",
            expertise:"",
            projectworkbefore:"",
            systemexperiencewith:"",
            password2:"",
            loading:false,
            showother:false,
            showconfirmation:false,
            tocisreaded:false,
            maskedemail:"",
            terms:false,
            termslink:"https://food-sight.com/terms-and-conditions/client-specific-terms-conditions/",
            sectors:[],
            othersector:"",
            usertype:"client",
        };
        this.engine = new RequestEngine();
    }

    async register(){
        const {password,password2,terms,tocisreaded,email,showother,othersector} = this.state
        if(password2!=password){
            Utilites.showErrorMessage("Please Confirm Password");
            return;
        }


        if(!tocisreaded){
            Utilites.showErrorMessage("Please Read The Terms & Conditions");
            return;
        }
        if(!terms){
            Utilites.showErrorMessage("Please Accept Terms & Conditions");
            return;
        }

        if(email.length<1 || password.length<1){
            Utilites.showErrorMessage("Please fill all required input");
            return;
        }
        if(showother && othersector.length<1){
            Utilites.showErrorMessage("Please fill all other input");
            return;
        }

        try {
            this.setState({loading : true})
            const result = await this.engine.register(this.state)
            if(result.status === 200 && result.data.success){
                this.setState({
                    showconfirmation:true,
                    maskedemail:Utilites.maskEmail(this.state.email)
                })

            }else{
                Utilites.showErrorMessage(result.data.message);
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong registration info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }

    renderClientInfo(){
        const {company, country,interestedin} = this.state

        return (<>
            <Row>
                <Col md="6" >
                    <FormRow label="Company name" className={"mr-2"} name="company" data={company}   changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="6" >
                    <SelectRow label="Country"   name="country"  defaultValue={country}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="What are you interest in" type={"textarea"} name="interestedin" data={interestedin}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            </>)
    }

    renderExpertInfo(){
        const {
            birthday,
            employmentstatus,
            country,
            educational,
            expertise,
            projectworkbefore,
            major,
            gender,
            systemexperiencewith} = this.state

        return (<>
            <Row>
                <Col md="6" >
                    <SelectRow label="Employement status"  className={"mr-2"} name="employmentstatus"  defaultValue={employmentstatus}  data={this.createSelectValue(employmentstatuslist)} changeInput={this.changeInput.bind(this)} />
                </Col>
                <Col md="6" >
                    <SelectRow label="Country"   name="country"  defaultValue={country}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />

                </Col>
            </Row>
            <Row>
                <Col md="6" >
                    <SelectRow label="Gender"  name="gender"  className={"mr-2"} defaultValue={gender}  data={this.createSelectValue(["Male","Female"])} changeInput={this.changeInput.bind(this)} />
                </Col>

                <Col md="6" >
                    <FormRow label="Date of Birth" type={"date"} name="birthday" data={birthday}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>

            <Row>
                <Col md="12" >
                    <SelectRow label="Educational Background"  name="educational"  defaultValue={educational}  data={this.createSelectValue(educationlist)} changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="Major"  name="major" data={major}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>

            <Row>
                <Col md="12" >
                    <SelectRow label="Expertise Level"  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(expertiselist)} changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="What are the food safety and quality projects you have worked on before" type={"textarea"} name="projectworkbefore" data={projectworkbefore}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="What are the food safety system you have experience in ?" type={"textarea"} name="systemexperiencewith" data={systemexperiencewith}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
        </>)
    }

    renderConfirmation(){
        return (
            <div className="login-page">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="8" md="8">
                            <Card className="card-login" style={{marginTop:100,padding:30}}>
                                <CardBody>

                                        <Row >
                                            <Col md="6">
                                                <h5 className={"bold"}>Verification </h5>
                                            </Col>

                                        </Row>

                                    <div>


                                                <div>
                                                    You have successfully signed up , in order to start exploring the FoodSignt platform , a confirmation link was sent to the email address {this.state.maskedemail}
                                                </div>
                                                <div>Please use the link to complete email verification and access the platform</div>



                                        <div className={{}}>

                                            <Link to={"/auth/login"} className="pull-right" >Continue to Sign in</Link>
                                        </div>
                                    </div>



                                </CardBody>


                            </Card>



                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"

                />
            </div>
        );
    }
    render() {
        const {title,
            first,
            last,
            email,
            password,
            usertype,
            phone,
            loading,
            sectors,
            showother,
            othersector,
            password2,
            terms,
            termslink,
            showconfirmation,
            workphonerror
            } = this.state

        if(showconfirmation){
            return this.renderConfirmation();
        }
        return (
            <div className="login-page">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="8" md="8">
                            <Card className="card-login" style={{marginTop:100,padding:30}}>
                                    <CardBody>
                                        <AvForm   className="form-horizontal" id="TypeValidation">

                                            <Row >
                                                <Col md="6">
                                                    <h5 className={"bold"}>Register </h5>
                                                </Col>
                                                <Col md="6">
                                                    <Link to={"/auth/login"} className="pull-right" >Already have an account?</Link>
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col md="12" >
                                                    <label>User Type</label><br/>
                                                    <input onClick={()=>{
                                                        this.setState({usertype:"client",termslink:"https://food-sight.com/terms-and-conditions/client-specific-terms-conditions/"})
                                                    }} type="radio" id="client" name="gender" value="client" checked={usertype=="client"?"checked":""}/>
                                                        <label htmlFor="client" style={{    position: "relative",
                                                            top: -2}}> &nbsp; <span   id="TooltipExample">Client</span></label> &nbsp;<input type="radio" id="expert" name="gender" onClick={()=>{
                                                    this.setState({usertype:"expert",termslink:"https://food-sight.com/terms-and-conditions/expert-specific-terms-conditions-foodsight/"})
                                                }} checked={usertype=="expert"?"checked":""} value="expert"/>
                                                            <label htmlFor="expert" style={{    position: "relative",
                                                                top: -2}}> &nbsp; <span   id="TooltipExample2">Expert</span></label>




                                                    <Tooltip placement="bottom" style={{background:"black",color:"white"}} isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={()=>{
                                                        this.setState({
                                                            tooltipOpen:!this.state.tooltipOpen
                                                        })
                                                    }}>
                                                        Food business owner, food business team member, entity supporting in a way or another a food business owner...
                                                    </Tooltip>


                                                    <Tooltip placement="bottom" style={{background:"black",color:"white"}} isOpen={this.state.tooltipOpen2} target="TooltipExample2" toggle={()=>{
                                                        this.setState({
                                                            tooltipOpen2:!this.state.tooltipOpen2
                                                        })
                                                    }}>
                                                        Food industry specialist with experience in one or more of the agri-food sectors, one or more of the food safety & quality systems, can work as a freelancer on FoodSight services based on demand.
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop:10}}>
                                                <Col md="4"  >
                                                    <SelectRow label="Title" className={"mr-2"} name="title"  defaultValue={title}  data={this.createSelectValue(titlelist)} changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                                <Col md="4"  >
                                                    <FormRow label="Your First Name" className={"mr-2"} name="first" data={first}   changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                                <Col md="4" >
                                                    <FormRow label="Your Last Name" name="last" data={last}   changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col md="6" >
                                                    <FormRow label="Your Email" errorMessage={"Please write a valid email"} className={"mr-2"} name="email" data={email} type={"email"}   changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                                <Col md="6" style={{padding:0}} >

                                                    <>
                                                        <div>
                                                            <Label >Your Phone Number<span style={{color:"red"}}>*</span></Label>
                                                        </div>
                                                        <FormGroup >
                                                            <PhoneInput
                                                                country={'lb'}
                                                                value={phone}
                                                                inputProps={{
                                                                }}
                                                                isValid={(inputNumber, country, countries) => {
                                                                    return countries.some((country) => {
                                                                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                                                    });
                                                                }}
                                                                onChange={(workhomephone) =>{
                                                                    if(isValidPhoneNumber("+"+workhomephone) === true){
                                                                        console.log("valid")
                                                                        this.setState({
                                                                            workphonerror:""
                                                                        })



                                                                    }else{
                                                                        console.log("invalid")

                                                                        this.setState({
                                                                            workphonerror:"phone error"
                                                                        })
                                                                    }

                                                                    debugger
                                                                    this.setState({ phone :"+"+workhomephone})
                                                                } }
                                                            />

                                                        </FormGroup>
                                                        <div className={"phoneerror"}>{workphonerror}</div>
                                                    </>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" >
                                                    <FormRow type={"password"} label="Your Password" className={"mr-2"} name="password" data={password}   changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                                <Col md="6" >
                                                    <FormRow type={"password"} label="Re-type password" name="password2" data={password2}   changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12"  >
                                                    <SelectRow isMulti={true} label={usertype=="expert"?"in what sector do you have experience?":"what sector do you operate in?"}  name="sectors"  defaultValue={sectors}  data={this.createSelectValue(sectoroperatorin)} changeInput={(e)=>{
                                                        this.setState({ sectors: e })
                                                        const otherfound = e.find(i=>i.value.toLowerCase()=="other");
                                                        if(otherfound){
                                                            this.setState({
                                                                showother: true
                                                            });
                                                        }else{
                                                            this.setState({
                                                                showother: false
                                                            });
                                                        }
                                                    }} />
                                                </Col>
                                            </Row>


                                            {showother && <Row>
                                                <Col md="12" >
                                                    <FormRow label="Other" name="othersector" data={othersector}   changeInput={this.changeInput.bind(this)} />
                                                </Col>
                                            </Row>}
                                            {usertype=="client" ? this.renderClientInfo() : this.renderExpertInfo()}

                                            <Row>
                                                <Col md="12" >
                                                    <input onClick={()=>{
                                                        this.setState({terms:true})
                                                    }} type="checkbox" id="terms" name="terms" value="terms" checked={terms}/>
                                                    <label htmlFor="terms" style={{    position: "relative",
                                                        top: -2}}> &nbsp; Agree with <a onClick={() => {
                                                        this.setState({
                                                            tocisreaded: true
                                                        });
                                                    }} target={"_blank"} href={termslink}>Terms & Conditions</a> </label> &nbsp;
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                    <div className={"centrino"}>
                                        <ButtonLoader className=" mb-3" onClick={() => this.register()}   style={{width: "100%",
                                            margin: "auto"}} color="primary" loading={loading}>Register</ButtonLoader>
                                    </div>

                                </Card>



                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"

                />
            </div>
        );

    }
}

export default Register;
