import React, { useRef, useState, useEffect } from "react";

import {
  FileUploadContainer,
  FormField,
  DragDropText,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel
} from "./file-upload.styles";
import upload from "../../assets/img/upload.png";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  oldvalue,
                      lite,
                      hideicon,
                      index,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(oldvalue);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };



useEffect(() => {
  setFiles(oldvalue);
}, [oldvalue]);


  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = async (e) => {

    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }

  };

  const removeFile = async (fileName,fileid) => {
    if(window.confirm("do you want to delete the file!")){
       delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    }


  };



  let litestyle = {}

  if(lite){
    litestyle = {margin:0,border:0,padding:0}
  }

  return (
    <>
      <FileUploadContainer style={litestyle}>
        <InputLabel>{label}</InputLabel>
        {!lite && <DragDropText>File types allowed: jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx (MAX:10MB) </DragDropText>}
        {!hideicon && <img src={upload} style={{width:100}} />}
         <form>

        <FormField
          type="file"
          id={"filemin"+index}
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
          </form>

      </FileUploadContainer>
      <FilePreviewContainer>
        <PreviewList id={"lite"+lite}>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile= false;
            if(file.type){
              isImageFile = file.type.split("/")[0] === "image";
            }
            return (
              <PreviewContainer key={fileName}>
                <div >
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span onClick={() => window.open(file.url)} className="cursor">{file.name}</span>
                    <aside>
                      <RemoveFileIcon
                        className="fas fa-trash-alt"
                        onClick={() => removeFile(fileName,file.id)}
                      >delete
                      </RemoveFileIcon>
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

export default FileUpload;
