import React from "react";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    Modal,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import FormRow from "../../components/Row/FormRow";
import {AvForm} from "availity-reactstrap-validation";
import LoadMore from "../../core/LoadMore";
import {Link} from "react-router-dom";
import {Utilites} from "../../core/Utilites";

class RequestList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            makeoffer:false,
            viewrequest:false,
            viewproject:false,
            cancelreason:"",
            viewoffer:false,
            cancelrequest:false,
            retractdeleterequest:false,
            editoffer:false,
            isproject:false,
            emptyrequestlink:false,
            pathlink:"list",
            pathname:"request",
            dataSource: [],
            selectedrequest:{},



        };

        this.engine = new RequestEngine();
    }


    componentDidMount() {

        const pathlink = this.props.pathlink;
        const makeoffer = this.props.makeoffer;
        const viewrequest = this.props.viewrequest;
        const pathname = this.props.pathname
        const viewoffer = this.props.viewoffer
        const retract = this.props.retract;
        const retractdeleterequest = this.props.retractdeleterequest;
        const cancelrequest = this.props.cancelrequest;
        const emptymsg = this.props.emptymsg;
        const viewproject = this.props.viewproject;
        const isproject = this.props.isproject;
        const editoffer = this.props.editoffer;
        const emptyrequestlink = this.props.emptyrequestlink;


        if(pathlink){
            this.setState({
                pathlink,
                makeoffer,
                viewrequest,
                viewproject,
                pathname,
                viewoffer,
                retract,
                retractdeleterequest,
                cancelrequest,
                emptymsg,
                isproject,
                editoffer,
                emptyrequestlink
            },()=>{
                this.callPage()
            })
        }

         // pathlink

    }


    viewRequest(data) {
        const {pathname} = this.state
        this.props.history.push({
            pathname:'/admin/'+pathname+'/view/'+data.id,
            data
        });
    }

    viewProject(data) {
        const {pathname} = this.state
        this.props.history.push({
            pathname:'/admin/'+pathname+'/view/'+data.id,
            data
        });
    }

    makeOffer(params) {
        const data={
            request:params._id
        }
        this.props.history.push({
            pathname:'/admin/offer/new/'+params.id,
            data
        });
    }



    async sendRetractRequest(request,delele= false){
        let response = {};
        let data={
            offer:request,
        }

        if(delele){
            data={
                request:request,
            }
            response = await  this.engine.postItem("offer","/delete",data);
        }else{
            response = await  this.engine.postItem("offer","/retract",data);
        }



        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                    this.callPage()
                }
            );
        }
        this.props.loadingAction(false);
    }


    async sendCancelRequest(){
        const {pathname,selectedrequest,cancelreason} = this.state
        if(cancelreason.length<2){
            Utilites.showErrorMessage("Cancel Reason is required");
            return;
        }
        const data={
            request:selectedrequest._id,
            cancelreason:cancelreason
        }
        const response = await  this.engine.postItem(pathname,"/cancelrequest",data);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                this.callPage()
                }
            );
        }
        this.props.loadingAction(false);
    }

    async callPage() {
        const {pathlink,pathname} = this.state

        this.setState({
                isLoading: true,
            }
        );
        const response = await  this.engine.getItem(pathname,pathlink);

        if(response && response.status === 200){


            this.setState({
                    isLoading: false,
                    dataSource: response.data.data
                }
            );
        }
        this.props.loadingAction(false);
    }





    renderModel() {
        const {showcancel,cancelreason} = this.state;
        return (<>
            <Modal
                isOpen={showcancel}
                toggle={()=>{
                    this.setState({
                        showcancel:false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <Button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={()=>{
                            this.setState({
                                showcancel:false
                            })
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                    <h4 className="title title-up">Confirmation</h4>
                </div>
                <div className="modal-body">
                    <AvForm   className="form-horizontal" id="TypeValidation">
                    <div className='react-confirm-alert-body'>
                        <h6>You are canceling a project request, are you sure?</h6>
                        <FormRow placeholder="Reason for cancel" name="cancelreason" data={cancelreason}   changeInput={this.changeInput.bind(this)} />
                    </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                           // className="btn-link"
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showcancel:false
                                })

                                this.sendCancelRequest();
                            }}
                        >
                            Ok
                        </Button>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showcancel:false,
                                })
                            }}
                        >
                            Cancel
                        </Button>



                    </div>

                </div>
            </Modal>
        </>)
    }

    renderEmpty(){

        const {emptymsg,emptyrequestlink} = this.state
        if(emptyrequestlink){
            return (<div className="content">
                <Row>
                    <Col md="12">
                        <Card>

                            <CardHeader>
                                <CardTitle tag="h4" className="pull-left">
                                    You have no open requests.

                                    You can go to the <Link to={"/admin/request/new"} id={"linkbtn"}>new request</Link> page and submit your project request.

                                    Our experts are one click away!</CardTitle>
                                <br/>
                            </CardHeader>
                            <br/>
                        </Card>
                    </Col>
                </Row>

            </div>)
        }
        return (<div className="content">
            <Row>
                <Col md="12">
                    <Card>

                        <CardHeader>
                            <CardTitle tag="h4" className="pull-left">{emptymsg}</CardTitle>
                            <br/>
                        </CardHeader>
                        <br/>
                    </Card>
                </Col>
            </Row>

        </div>)
    }
    render() {
        const {dataSource,isLoading,makeoffer,viewrequest,viewoffer,retract,cancelrequest,viewproject,isproject,retractdeleterequest,editoffer} = this.state;

        const {showofferdeadline,detectoffercount} = this.props

        if(isLoading){
            return this.renderProgress()
       }


        return (
            <>

                <div className="content">


                    {this.renderModel()}
                    {dataSource.length==0 &&  this.renderEmpty()}
                    {dataSource.map((item,index)=>{

                        return  (  <Card><Row key={index}>

                            <Col md="10">
                                    <CardHeader>
                                        <CardTitle tag="h4" className="pull-left" style={{fontWeight:"bold"}}>{item.title} {item.category && "- "+item.category}</CardTitle>
                                    </CardHeader> <br/> <br/>
                                    <CardBody>
                                        <LoadMore description={item.description} />

                                    </CardBody>

                                {isproject &&
                                <CardBody style={{marginTop:0,paddingTop:0}}>
                                    <CardTitle tag="h4" style={{marginTop:10,marginBottom:25,fontWeight:"bold"}} className="pull-left" >Budget (USD) :<span className={"normaltxt"}>{item.cost}</span>    Duration (Days): <span className={"normaltxt"}>{item.duration}</span></CardTitle>

                                </CardBody>}
                            </Col>
                            <Col md="2">
                               <div className="pull-right floatbtn" style={{width:190,padding:10}}>
                                            <CardTitle tag="h6" className={"status"} >{item.status}</CardTitle>
                                            {viewrequest  && <Button className={"emptybtn"}  onClick={()=>{
                                                this.viewRequest(item);
                                            }} >View Request</Button>}

                                   {viewproject  && <Button className={"emptybtn"}  onClick={()=>{
                                       this.viewProject(item);
                                   }} >View Project</Button>}



                                            {cancelrequest && <Button className={"rawbtn"}  onClick={(e)=>{
                                                this.setState({
                                                    showcancel:true,
                                                    selectedrequest:item
                                                })
                                            }} >Cancel Request</Button>}




                                            {viewoffer && <Button className={"emptybtn"}  onClick={()=>{
                                                if(item.status.includes("config")){
                                                    this.props.history.push({
                                                        pathname:'/admin/offer/send/'+item.id,
                                                        item
                                                    });
                                                }else{
                                                    this.viewRequest(item);
                                                }

                                            }} >View Offer</Button>}

                                   {editoffer && <Button className={"emptybtn"}  onClick={()=>{

                                           this.props.history.push({
                                               pathname:'/admin/offer/edit/'+item.id,
                                               item
                                           });
                                   }} >Edit Offer</Button>}

                                   {makeoffer &&  <Button  color="primary" onClick={()=>{
                                       this.makeOffer(item);
                                   }} >Make Offer</Button>}

                                            {retract && <Button className={"rawbtn"}  onClick={()=>{
                                                this.sendRetractRequest(item._id);
                                            }} >retract</Button>}

                                   {retractdeleterequest && <Button className={"rawbtn"}  onClick={()=>{
                                       this.sendRetractRequest(item._id,true);
                                   }} >retract</Button>}



                                   <div style={{float:"right",textAlign: "right",fontSize: 11,marginTop:20,marginRight:15,fontWeight:"bold"}}>Created Date : {this.renderDate(item.createdAt)}</div>
                                   {!showofferdeadline && item.deadline && <div style={{float:"right",textAlign: "right",fontSize: 11,marginTop:20,marginRight:15,fontWeight:"bold"}}>Deadline : {item.deadline}</div>}
                                   {showofferdeadline && <div style={{float:"right",textAlign: "right",fontSize: 11,marginTop:20,marginRight:15,fontWeight:"bold"}}>5 days to submit the offer</div>}



                                        </div>

                            </Col>

                        </Row>
                            {detectoffercount && item.offercount ==0 && <div style={{marginLeft:20}}> <CardTitle tag="h4" className="pull-left">No Offer Found , Expect to receive offer(s) from expert(s) within 10 working days from this date</CardTitle></div>}
                        </Card>)
                    })}


                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(RequestList);
