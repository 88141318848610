import React,{useState,useEffect} from 'react';
import {
    CardTitle, CardHeader, CardBody, Row, Col
} from "reactstrap";
import FormRow from "../../components/Row/FormRow";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import RequestMiniInformationAdv from "./RequestMiniInformationAdv";
import {AvForm} from "availity-reactstrap-validation";

function RequestMiniInformation({requestid,requesthints}) {
    const [ requestinfo,setRequestinfo]=useState({})
    const [ loading,setLoading]=useState(true)

    useEffect(() => {

        prepareData();
    },[]);
    useEffect(() => {

        prepareData();
    },[requestid]);

    const prepareData = async () => {
        if(requestid.length<2){
            setLoading(false)
            return;
        }

        let engine = new RequestEngine();

        const response = await  engine.getItem("request","/info/"+requestid);

        if(response && response.status === 200){


            setRequestinfo({
                    ...response.data.data.info,
                }
            );
        }

        setLoading(false)
    }
    return (
       <div>
           <CardHeader>
               <CardTitle tag="h4">Request Information</CardTitle>
           </CardHeader>
           <Row>
               <Col sm="7">
               <CardBody>
                   <FormRow label="title" viewonly={true} name="name" data={requestinfo.title}    />
                   <FormRow label="Duration (Days)"  viewonly={true} type={"number"} name="duration" data={requestinfo.duration}    />
                   <FormRow viewonly={true} label="Start date"   name="startdate" data={requestinfo.startdate}    />
                   <FormRow label="End Date"  viewonly={true}  name="deadline"   data={requestinfo.deadline}  />
                   <FormRow label="category"  viewonly={true}  name="category"   data={requestinfo.category}  />
                   <FormRow label="invited"  viewonly={true}  name="category"   data={Utilites.renderDate(requestinfo.createdAt)}  />
                   <RequestMiniInformationAdv data={requestinfo} />

                   <div>
                       {requestinfo.attachments_url && requestinfo.attachments_url.map((i,index)=>{
                           return <li key={index}><a target={"_blank"} href={i.url}>{i.name}</a> </li>
                       })}
                   </div>
                   <hr/>
               </CardBody>
               </Col>
               {requesthints && <Col sm="5" className={"pr-5"}>
                   <ol>
                      <p><span style={{fontWeight:"bold"}}>The above-listed details and estimations are received by the client.</span>
                      </p>

                       <p>
                           You are invited to add your proper estimations and details in a milestone breakdown.

                       </p>
                      <p>
                          Please be reminded that you can always request more clarification on the request from FoodSight team and <a href={"https://support.food-sight.com/"} target={"_blank"}>get in touch</a> with us for more information or for any issue faced.

                      </p>
                     <p>
                         Our aim towards the clients is to provide services in a fast, easy and affordable way, and we intend to meet the client's expectation to keep him satisfied and gain his trust to keep on working with us.

                     </p>

                      <p>
                          For that we recommend the following :
                      </p>

                       <p>a. Click on “retract offer” immediately in case you are not available to submit an offer for this assigned request</p>

                       <p>b. <a href={"https://support.food-sight.com/"} target={"_blank"}>Get in touch</a> with FoodSight team immediately once a request is received in case foreseeing a delay in delivering the offer - exceeding 5 working days.”</p>

                       <p style={{color:"red"}}>c. Submit your offer within 5 working days.</p>
                   </ol>
               </Col>}
           </Row>

       </div>

    );
}

export default RequestMiniInformation;
