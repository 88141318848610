import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col, Button, Table
} from "reactstrap";


import { Stepper } from 'react-form-stepper';
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import SelectRow from "../../components/Select/SelectRow";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import {Utilites} from "../../core/Utilites";

class RequestAward extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            title:"",
            isLoading: false,
            allowphysical:false,
            step:"0",
            method:"Wire Transfer",
            horizontalTabs:"paymenttype1",
            methodlist:["Wire Transfer","Physical Payment"],
            category:"",
            awardedoffer:false,
            userid:"",
            user:"",
            _id:"",
            loading : false,
            status:""
        };
        this.pathname = "request";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        const userid = localStorage.getItem('userid');
        if(userid){
            this.setState({
                userid
            })
        }

        if (edit){
            this.setState({
                ...edit,
            });
        }


        this.callPage()
    }

    async sendactivateproject(){
        const offerid = this.props.match.params.id
        this.setState({
            isLoading:true
        })
        const response = await  this.engine.getItem("project","/activate/"+offerid);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                this.props.history.push('/admin/request/archived');
                }
            );
        }
        this.props.loadingAction(false);
    }
    async callPage() {
        this.setState({
                isLoading: true,
            }
        );

        const offerid = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/payment/"+offerid);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    ...response.data.data.data,
                    allowphysical:response.data.data.allowphysical
                },()=>{
                    if(!this.state.allowphysical){
                        this.setState({
                            methodlist:["Wire Transfer"]
                        })
                    }else{
                        this.setState({
                            methodlist:["Wire Transfer","Physical Payment"]
                        })
                    }
                }
            );
        }
        this.props.loadingAction(false);
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })



        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    viewOffer(data) {
        if(data.status!="awarded"){
            data.showaward = true;
        }
        debugger
        this.props.history.push({
            pathname:'/admin/offer/view/'+data.id,
            data
        });
    }



    async awardoffer(){

        const data={
            offer:this.props.match.params.id
        }
        const response = await  this.engine.postItem("offer","/award",data);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                    debugger
                    //this.props.history.push('/admin/request/reward/'+response.data.data.requestid);
                }
            );
        }
    }

    async updatePayment() {
        this.setState({
                isLoading: true,
            }
        );
        const {step,method} = this.state;
        const offerid = this.props.match.params.id
        const data ={
            offerid:offerid,
            step:step,
            method:method
        }
        const response = await  this.engine.postItem(this.pathname,"/payment/update",data);

        if(response.status === 200 && response.data.success){

            if(step==2){
                this.awardoffer();
            }
            debugger
            this.setState({
                    isLoading: false,
                    ...response.data.data.data,
                    allowphysical:response.data.data.allowphysical
                },()=>{
                    if(!this.state.allowphysical){
                        this.setState({
                            methodlist:["Wire Transfer"]
                        })
                    }else{
                        this.setState({
                            methodlist:["Wire Transfer","Physical Payment"]
                        })
                    }
                }
            );
        }else{
            this.setState({
                isLoading: false,
                step:step-1
            })
            Utilites.showErrorMessage(response.data.message);
        }

        this.props.loadingAction(false);
    }

    async sendAwardRequest(offerid){
        const data={
            offer:offerid,
        }
        const response = await  this.engine.postItem("offer","/award",data);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                    Utilites.showSucessMessage("Awarded!")

                    this.props.history.push('/admin/request/archived');

                }
            );
        }
        this.props.loadingAction(false);
    }


    renderStep1() {

        return (
            <Card className={"p-3"}>


                <CardHeader>
                <Row>
                    <Col md="12" style={{textAlign:"center"}}>

                        Dear Client, please accept your Client specific <a href={"https://food-sight.com/terms-and-conditions/client-specific-terms-conditions/"} target={"_blank"}>T&Cs</a>   listing all the details you need to know about the project process, payment modalities & terms, and other important details you need to be reminded of. ”
                    </Col>

                </Row>

                    <Row>
                        <Col md="12">

                            <Button className={"emptybtn"}  onClick={()=>{
                                this.props.history.goBack();
                            }} >Cancel</Button>

                            <Button  color="primary" onClick={()=>{
                                this.setState({
                                    step:1
                                },()=>{
                                    this.updatePayment();
                                })
                            }} >Accept</Button>

                         </Col>

                    </Row>



                </CardHeader>

            </Card>
        )

    }

    renderStep2() {

        const {allowphysical} = this.state
        return (
            <Card className={"p-3"}>
                <CardBody>
                    <div className="nav-tabs-navigation">
                        <div className="nav-tabs-wrapper">

                            <div style={{textAlign:"center"}}>
                                Dear Client , please be reminded to complete your profile , and upload you MOF & Commercial register (CR) certificates.
                            </div>
                            <br/>
                            <Nav id="tabs" role="tablist" tabs>
                                <NavItem>
                                    <NavLink
                                        aria-expanded={this.state.horizontalTabs === "paymenttype1"}
                                        data-toggle="tab"
                                        href="#pablo"
                                        role="tab"
                                        className={
                                            this.state.horizontalTabs === "paymenttype1"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() =>
                                            this.setState({ horizontalTabs: "paymenttype1",method:"Wire Transfer" })
                                        }
                                    >
                                        Wire Transfer
                                    </NavLink>
                                </NavItem>


                                {allowphysical && <NavItem>
                                    <NavLink
                                        aria-expanded={
                                            this.state.horizontalTabs === "paymenttype3"
                                        }
                                        data-toggle="tab"
                                        href="#pablo"
                                        role="tab"
                                        className={
                                            this.state.horizontalTabs === "paymenttype3"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() =>
                                            this.setState({ horizontalTabs: "paymenttype3",method:"Physical Payment" })
                                        }
                                    >
                                        Physical Payment
                                    </NavLink>
                                </NavItem>}

                                <NavItem>
                                    <NavLink
                                        aria-expanded={this.state.horizontalTabs === "paymenttype2"}
                                        data-toggle="tab"
                                        href="#pablo"
                                        role="tab"
                                        className={
                                            this.state.horizontalTabs === "paymenttype2"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() =>
                                            this.setState({ horizontalTabs: "paymenttype2",method:"OMT" })
                                        }
                                    >
                                        OMT
                                    </NavLink>
                                </NavItem>

                            </Nav>
                        </div>
                    </div>

                    <TabContent
                        className="text-center"
                        id="my-tab-content"
                        activeTab={this.state.horizontalTabs}
                    >
                        <TabPane tabId="paymenttype1"  role="tabpanel">
                            <p>
                                <p>By choosing this option you agree to transfer the project fees to FoodSight bank account, following the details below:
                                </p>
                                <ul>
                                    <li>
                                        Beneficiary name: FoodSight SAL
                                    </li>
                                    <li>
                                        Bank Name: Arab Bank
                                    </li>
                                    <li>
                                        Bank account Number: 0338-774559-800
                                    </li>
                                    <li>
                                        IBAN: LB71 0005 0000 0000 3387 7455 9800
                                    </li>
                                    <li>
                                        Account Currency: LBP
                                    </li>
                                    <li>
                                        Swift code: ARABLBBX
                                    </li>
                                </ul>
                                <hr/>
                                <ul>

                                    <li>Bank Account Number: 0338-774559-922</li>
                                    <li>IBAN LB75 0005 0000 0000 3387 7455 9922 </li>
                                    <li>Account Currency: USD </li>
                                </ul>
                                  </p>
                        </TabPane>

                        <TabPane tabId="paymenttype3"  role="tabpanel">
                            <p>
                                Choosing this option will require passing by our offices or meeting us at your offices, to submit your payment in-person, either in Cash or in bankers Cheque </p>
                        </TabPane>

                        <TabPane tabId="paymenttype2"  role="tabpanel">
                            <p>
                                By choosing this option you agree to transfer the project fees to our OMT account, to one of the following accounts:
                                <ul>
                                   <li>Fidele Mekhayel El Achkar +96170910632</li>
                                   <li>Nathalie Abdo Nasr +96170099770</li>
                                </ul>
                            </p>
                        </TabPane>
                    </TabContent>





                    <Row>
                        <Col md="12">

                            <Button className={"emptybtn"}  onClick={()=>{
                                this.props.history.goBack();
                            }} >Cancel</Button>

                            <Button  color="primary" onClick={()=>{
                                this.setState({
                                    step:2
                                },()=>{
                                    this.updatePayment()
                                })
                            }} >Continue</Button>

                        </Col>

                    </Row>
                </CardBody>
            </Card>
        )

    }


    renderStep3() {

        const {status} = this.state
        return (
            <Card className={"p-3"}>


                <CardTitle tag="h4" style={{marginTop:10,marginLeft:10,fontWeight:"bold"}} className="pull-left" >
                    {status.includes("received") ? "Payment Received":"Payment Pending Confirmation"}
                </CardTitle>

                <CardHeader>
                    <Row>
                        <Col md="12" style={{textAlign:"center"}}>
                            Thank you dear Client for selecting this payment method. You benefit from 10 working days’ grace period to submit your 1st payment equal to 60% of the total project fees. FoodSight team will coordinate with you to make sure your payment is successfully received. Upon validation of the payment, you are invited to Start the project where you will be introduced with the expert to get started”   </Col>

                    </Row>


                </CardHeader>

            </Card>
        )

    }
    render() {
        // taking all the states
        const {isLoading,step }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <Stepper
                        steps={[{ label: 'Accept Terms & Conditions' }, { label: 'Make Payment' }, { label: 'Initiate Project' }]}
                        activeStep={step}
                        ConnectorStyleProps={{
                            activeColor:"#3E50B5"
                        }}
                        activeColor={"#3E50B5"}
                        completedColor={"#3E50B5"}
                    />
                    {step==0 && this.renderStep1()}

                    {step==1 && this.renderStep2()}

                    {step==2 && this.renderStep3()}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(RequestAward);

