import React,{useEffect} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "core/redux/store";
import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import { Auth0Provider } from '@auth0/auth0-react';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

const history = createBrowserHistory();

let isloggedin = localStorage.getItem('isloggedinfront');
let usertype = localStorage.getItem('usertype');


let redirectionpath = "/admin/dashboard";
if(usertype=="client"){
  redirectionpath = "/admin/request/list";
}
if(usertype=="expert"){
  redirectionpath = "/admin/request/assigned";
}
if(!isloggedin || isloggedin == "false"){
    redirectionpath = "/auth/login";
}

const loader = document.querySelector('.loader');
// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

const App = ({hideLoader}) => {
  useEffect(hideLoader, []);
  return ( <Provider store={configureStore}>
    <ReactNotification />

    <Router history={history}>
      <Auth0Provider
          domain="foodsight.us.auth0.com"
          clientId="Ha1mYfPfoniSfMXBstQoZ6Gp85ugZYGE"
          redirectUri={window.location.origin+"/auth/login"}
      >
      <Switch>
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to={redirectionpath} />
      </Switch>
      </Auth0Provider>
    </Router>
  </Provider>
 );
};
ReactDOM.render(
    <App hideLoader={hideLoader}
         showLoader={showLoader} />,
  document.getElementById("root")
);


// https://demos.creative-tim.com/paper-dashboard-pro-react/
