import React from "react";


import {employmentstatuslist,sectoroperatorin,titlelist,educationlist,expertiselist} from "../../core/staticdata";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup, Modal, Button, Label
} from "reactstrap";

import { withRouter} from "react-router-dom";
import startsWith from 'lodash.startswith';
import 'react-phone-input-2/lib/style.css'
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import ImageUpload from "../../components/CustomUpload/ImageUpload";

import {Utilites} from "../../core/Utilites";
import SelectRow from "../../components/Select/SelectRow";
import {selectCountryList} from "../../core/countries";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import ClientAddUpdate from "./ClientAddUpdate";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "react-phone-number-input";


class UserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            allowdelete:false,
            title:"",
            first:"",
            last:"",
            email:"",
            password:"",
            birthday:"",
            gender:"",
            major:"",
            company:"",
            country:"",
            mdf:null,
            fullmdf:"",
            interestedin:"",
            educational:"",
            phone:"",
            expertise:"",
            projectworkbefore:"",
            systemexperiencewith:"",
            preferedpaymentoption:"wire transfer",
            cv:null,
            picture:null,
            filename:"",
            fullpicture:"",
            fullcv:"",
            password2:"",
            employmentstatus:"",
            loading:false,
            loadingdelete:false,
            showdelete:false,
            showother:false,
            sectors:[],
            othersector:"",

            city:"",
            district:"",
            street:"",
            buildingname:"",
            banka_benfname:"",
            banka_iban:"",
            banka_number:"",
            banka_name:"",
            banka_swift:"",




        };
        this.engine = new RequestEngine();
        // cv
        this.idFileBase64 = "";
        this.idFileBase64mdf = "";
        this.idFileBase64picture = "";
    }



    componentDidMount() {
        this.callPage()
    }

    onChangeFile(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
        this.setState({cv:e.target.files[0],filename:e.target.files[0].name})
    }
    onChangeFilepic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64picture = result;
        });
        this.setState({picture:e.target.files[0]})
    }
    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const response = await  this.engine.getItem("user","/me");

        if(response && response.status === 200){


            this.setState({
                    isLoading: false,
                    ...response.data.data,
                }
            );
        }
        this.props.loadingAction(false);
    }


    onChangeFilemdf(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64mdf = result;
        });
        this.setState({mdf:e.target.files[0]})
    }
    async updateUser(){


        try {
            this.setState({loading : true})
            let data = {...this.state};

            data.cvbase64 = this.idFileBase64;
            data.picturebase64 = this.idFileBase64picture;
            data.mdfbase64 = this.idFileBase64mdf;

            const result = await this.engine.postItem("user","/updateprofile",data)
            if(result.status === 200 && result.data.success){
                Utilites.showSucessMessage("Profile Updated")
                localStorage.removeItem("firstlogin");
                localStorage.setItem('picture', result.data.data.picture);
                this.props.history.push('/admin/request/assigned');
                window.location.reload()
            }else{
                Utilites.showErrorMessage(result.data.message);
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong registration info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }

    renderClientInfo(){
        const {company,interestedin} = this.state

        return (<>
            <Row>
                <Col md="6" >
                    <FormRow label="Company name" className={"mr-2"} name="company" data={company}   changeInput={this.changeInput.bind(this)} />
                </Col>

            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="What are you interest in" type={"textarea"} name="interestedin" data={interestedin}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
        </>)
    }

    renderExpertInfo(){
        const {
            birthday,
            educational,
            employmentstatus,
            expertise,
            projectworkbefore,
            major,
            gender,
            systemexperiencewith} = this.state

        return (<>
            <Row>
                <Col md="6" >
                    <SelectRow label="Employement status"  required={true} className={"mr-2"} name="employmentstatus"  defaultValue={employmentstatus}  data={this.createSelectValue(employmentstatuslist)} changeInput={this.changeInput.bind(this)} />
                </Col>

                <Col md="6" className={"centrino"} >
                            <FormGroup >
                                <ImageUpload filename={this.state.filename} required={true} hideplaceholder={true} isfile={true} placeholder={this.state.fullcv} labelupload="Select CV" onChange={(e) => this.onChangeFile(e)}/>
                            </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6" >
                    <SelectRow label="Gender"  name="gender" required={true}  className={"mr-2"} defaultValue={gender}  data={this.createSelectValue(["Male","Female"])} changeInput={this.changeInput.bind(this)} />
                </Col>

                <Col md="6" >
                    <FormRow label="Date of Birth" required={true} type={"date"} name="birthday" data={birthday}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>

            <Row>
                <Col md="12" >
                    <SelectRow label="Educational Background"  required={true} name="educational"  defaultValue={educational}   data={this.createSelectValue(educationlist)} changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow label="Major"  required={true} name="major" data={major}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>

            <Row>
                <Col md="12" >
                    <SelectRow label="Expertise Level"  required={true}  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(expertiselist)} changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow required={true} label="What are the food safety and quality projects you have worked on before" type={"textarea"} name="projectworkbefore" data={projectworkbefore}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
            <Row>
                <Col md="12" >
                    <FormRow required={true} label="What are the food safety system you have experience in ?" type={"textarea"} name="systemexperiencewith" data={systemexperiencewith}   changeInput={this.changeInput.bind(this)} />
                </Col>
            </Row>
        </>)
    }


    async sendDeleteRequest(){

        this.setState({
            loadingdelete:true
        })
        const response = await  this.engine.postItem("user","/delete");
        if(response && response.status === 200){
            this.setState({
                loadingdelete: false,
                },()=>{
                localStorage.setItem('isloggedin', false);
                localStorage.setItem('managertype', null);
                localStorage.setItem('token', null);
                    // redirect to dashboard

                    this.props.history.push('/auth/login');
                }
            );
        }

    }





    renderModelDelete() {
        const {showdelete} = this.state;
        return (<>
            <Modal
                isOpen={showdelete}
                toggle={()=>{
                    this.setState({
                        showdelete:false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <Button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={()=>{
                            this.setState({
                                showdelete:false
                            })
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                    <h4 className="title title-up">Confirmation</h4>
                </div>
                <div className="modal-body">
                    <AvForm   className="form-horizontal" id="TypeValidation">
                        <div className='react-confirm-alert-body'>
                            <h6>Are you sure you want to delete ?</h6>
                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showdelete:false
                                })

                                this.sendDeleteRequest();
                            }}
                        >
                            Ok
                        </Button>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showdelete:false,
                                })
                            }}
                        >
                            Cancel
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }





    render() {
        const {title,
            first,
            last,
            email,
            usertype,
            phone,
            loading,
            loadingdelete,
            sectors,
            showother,
            othersector,
            allowdelete,
            city,
            district,
            street,
            buildingname,
            banka_benfname,
            banka_iban,
            banka_number,
            banka_name,
            banka_swift,
            country,
            workphonerror,
            isLoading,
            preferedpaymentoption
        } = this.state

        if(isLoading){
            return this.renderProgress();
        }


        let firstlogin = localStorage.getItem("firstlogin");

        if(usertype=="client"){
            return <ClientAddUpdate/>
        }
        return (
            <div className="content english">
                {firstlogin && <div style={{textAlign:"center",color:"red",fontWeight:"bold",fontSize:20}}>Please Complete your profile</div>}

                {this.renderModelDelete()}

                    <Row>
                        <Col  lg="12" md="12">
                            <Card className="card-login" style={{marginTop:10,padding:30}}>
                                <AvForm   onValidSubmit={() => this.updateUser()}   className="form-horizontal" id="TypeValidation">
                                <CardHeader>
                                    <CardTitle tag="h4">Basic Information


                                        {allowdelete && <ButtonLoader className=" mb-3"  style={{float: "right",
                                            fontSize:13,
                                            width: 155}} onClick={() => {

                                            this.setState({
                                                showdelete:true
                                            })

                                        }}    color="danger" loading={loadingdelete}>DELETE</ButtonLoader>}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                        <Row style={{marginTop:10}}>
                                            <Col md="4"  >
                                                <SelectRow label="Title" className={"mr-2"} name="title"  defaultValue={title}  data={this.createSelectValue(titlelist)} changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="4"  >
                                                <FormRow label="Your First Name" className={"mr-2"} name="first" data={first}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                            <Col md="4" >
                                                <FormRow label="Your Last Name" name="last" data={last}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" >
                                                    <FormRow label="Your Email" readonly={true} className={"mr-2"} name="email" data={email} errorMessage={"Please write a valid email"} type={"email"}  changeInput={this.changeInput.bind(this)} />

                                                    <div className={"mr-2 row"}>
                                                        <div className="form-group" style={{width: "100%"}}>
                                                        <div >
                                                            <Label >Your Phone Number <span style={{color:"red"}}>*</span></Label>
                                                        </div>
                                                        <FormGroup >
                                                            <PhoneInput
                                                                country={'lb'}
                                                                value={phone}
                                                                inputProps={{
                                                                }}
                                                                isValid={(inputNumber, country, countries) => {
                                                                    return countries.some((country) => {
                                                                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                                                    });
                                                                }}
                                                                onChange={(workhomephone) =>{
                                                                    if(isValidPhoneNumber("+"+workhomephone) === true){
                                                                        console.log("valid")
                                                                        this.setState({
                                                                            workphonerror:""
                                                                        })



                                                                    }else{
                                                                        console.log("invalid")

                                                                        this.setState({
                                                                            workphonerror:"phone error"
                                                                        })
                                                                    }

                                                                    this.setState({ phone :"+"+workhomephone})
                                                                } }
                                                            />

                                                        </FormGroup>
                                                        <div className={"phoneerror"}>{workphonerror}</div>
                                                        </div>
                                                    </div>


                                              </Col>

                                            <Col md="4" className={"centrino"} >
                                                <FormGroup >
                                                    <ImageUpload filename={"Mof"} info={"In case you do not have an MOF number, FoodSight will have to pay the proper tax for such case (7.5% of the offer’s amount), on behalf of you and the amount paid shall be deducted from your project fees"} hideplaceholder={true} isfile={true} placeholder={this.state.fullmdf} labelupload="MOF certificate" onChange={(e) => this.onChangeFilemdf(e)}/>
                                                </FormGroup>
                                            </Col>

                                            <Col md="4" className={"centrino"} >
                                                <FormGroup >
                                                    <ImageUpload filename={""} hideplaceholder={true} isfile={false} placeholder={this.state.fullpicture} labelupload="Select Profile Image" onChange={(e) => this.onChangeFilepic(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>


</CardBody>
                                        <CardHeader>
                                            <CardTitle tag="h4">Address Information</CardTitle>
                                        </CardHeader>

                                    <CardBody>

                                        <Row>

                                            <Col md="6" >
                                                <SelectRow label="Country"  required={true}   name="country"  className={"mr-2"} defaultValue={country}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />

                                            </Col>
                                            <Col md="6" >
                                                <FormRow label="City" required={true}  name="city" data={city}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md="6" >
                                                <FormRow label="District" required={false}  name="district" data={district} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />

                                            </Col>
                                            <Col md="6" >
                                                <FormRow label="Street" required={false}  name="street" data={street}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" >
                                                <FormRow label="Building name" required={false}  name="buildingname" data={buildingname}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>



                                    </CardBody>
                                    {usertype=="expert" && <div>

                                        <CardBody>
                                        <Row>
                                            <Col md="12" >
                                                <SelectRow label="Payment option" info={
                                                    preferedpaymentoption.includes("OMT") ?
                                                        "By choosing this option you agree to receive the fees of the project Throught OMT , make sure your basic information are properly entered as it will be used to issue the OMT transfer"
:preferedpaymentoption.includes("payment")?"Choosing this option will require passing by our offices to collect your payment in-person, either in Cash or in bankers Cheque":
                                                        "By choosing this option you agree to receive your fees for all future projects via bank transfer from FoodSight bank account. You can always come back and edit the selected method"

                                                        }  name="preferedpaymentoption"  defaultValue={preferedpaymentoption}  data={this.createSelectValue(["wire transfer","OMT cash transfer","physical payment"])} changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        {preferedpaymentoption.includes("wire") && <div>

                                            <CardHeader>
                                                <CardTitle tag="h4">Bank Account Details</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md="6" >
                                                        <FormRow label="Beneficary name" required={true} name="banka_benfname" data={banka_benfname} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />
                                                    </Col>
                                                    <Col md="6" >
                                                        <FormRow label="IBAN" required={true} name="banka_iban" data={banka_iban}   changeInput={this.changeInput.bind(this)} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6" >
                                                        <FormRow label="Bank Account Number" required={true} name="banka_number" data={banka_number} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />
                                                    </Col>
                                                    <Col md="6" >
                                                        <FormRow label="Bank Name" required={true} name="banka_name" data={banka_name}   changeInput={this.changeInput.bind(this)} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12" >
                                                        <FormRow label="Bank Swift Code" required={true} name="banka_swift" data={banka_swift}   changeInput={this.changeInput.bind(this)} />
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </div>}

                                    </div>}



                                    <CardHeader>
                                        <CardTitle tag="h4">Complete Your Profile</CardTitle>
                                    </CardHeader>

                                    <CardBody>

                                        <Row>
                                            <Col md="12"  >
                                                <SelectRow required={true} isMulti={true} label="in what sector do you have experience?"  name="sectors"  defaultValue={sectors}  data={this.createSelectValue(sectoroperatorin)} changeInput={(e)=>{
                                                    this.setState({ sectors: e })
                                                    const otherfound = e.find(i=>i.value.toLowerCase()=="other");
                                                    if(otherfound){
                                                        this.setState({
                                                            showother: true
                                                        });
                                                    }else{
                                                        this.setState({
                                                            showother: false
                                                        });
                                                    }
                                                }} />
                                            </Col>
                                        </Row>


                                        {showother && <Row>
                                            <Col md="12" >
                                                <FormRow label="Other" name="othersector" data={othersector}   changeInput={this.changeInput.bind(this)} />
                                            </Col>
                                        </Row>}
                                        {usertype=="client" ? this.renderClientInfo() : this.renderExpertInfo()}


                                </CardBody>
                                <div className={"centrino"}>
                                    <ButtonLoader className=" mb-3"    style={{width: "100%",
                                        margin: "auto"}} color="primary" loading={loading}>Update</ButtonLoader>
                                    <br/>



                                </div>
                                </AvForm>

                            </Card>



                        </Col>
                    </Row>


            </div>
        );

    }
}


const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(withRouter(UserAddUpdate));

