// src/App.js
import React, {useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonLoader from "./components/ButtonLoader/ButtonLoader";

function GApp(props) {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();


    useEffect(() => {

       if(user){
          props.triggerlogin(user);
       }
    },[user]);
    if (isLoading) {
      //s  return <div>Loading...</div>;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isAuthenticated) {
        return (
            <div>
                Hello {user.name}{' '}
                {2>5 &&<button onClick={() => logout({ returnTo: window.location.origin })}>
                    Log out
                </button>}
            </div>
        );
    } else {
        return <ButtonLoader className=" mb-3" onClick={loginWithRedirect} style={{width: "95%",
            margin: "auto"}} >Login</ButtonLoader>;
    }
}

export default GApp;
