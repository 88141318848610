import React,{useState,useEffect} from 'react';
import { CardTitle, CardHeader, Button
} from "reactstrap";
import {Utilites} from "./Utilites";

function LoadMore({description}) {
    const [ showreadmore,setShowreadmore]=useState(true)
    const [ fullload,setFullload]=useState(false)

    const minlength = 850;
    useEffect(() => {

       console.log(description && description.length)
        console.log("result ",(description && description.length<minlength))
        if(description && description.length<minlength){
            setShowreadmore(false)
        }
    },[description]);


    let descoutput = Utilites.truncate(description, !fullload?minlength:99999999999);

    if(descoutput.trim()<1){
        return <div></div>
    }

    return (
<div>
    <pre   dangerouslySetInnerHTML={{ __html: descoutput }} ></pre>
<div>
    {showreadmore && <a className={"rawbtn cursor"} style={{float: "left",fontSize: 11,background: "transparent"}} onClick={()=>{
        setFullload(!fullload)
    }}>{fullload?"show less":"more"}</a>}
</div>

</div>


    );
}

export default LoadMore;
