import React, {useRef} from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col, Button, Modal, Nav, NavItem, NavLink, TabContent, TabPane, DropdownToggle
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import {Utilites} from "../../core/Utilites";

import { Stepper } from 'react-form-stepper';
import FileUpload from "../../components/file-upload/file-upload.component";
import LoadMore from "../../core/LoadMore";
class ProjectMileStones extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loading : false,
            deliverablesloading:false,
            commentloading:false,
            lastpaymentmodal:false,
            horizontalTabs:"paymenttype1",
            needtomakelastpayment:0,
            comments:"",
            attachments_url:[],
            attachments_urldelivery:[],
            milestonedeliverables:[],
            offers:[],
            commentslist:[],
            milestone:{}
        };
        this.pathname = "milestone";
        this.engine = new RequestEngine();
        this.idFileBase64Attach = [];
        this.idFileBase64Attachdelivery = [];
    }

    componentDidMount() {
        this.callPage();
    }

    async makelastpayment(){
        const projectid = this.props.projectid
        const response = await  this.engine.getItem("project","/makelastpayment/"+projectid);
        if(response && response.status === 200){
           Utilites.showSucessMessage("Thank you")
            window.location.reload();
        }
        this.props.loadingAction(false);
    }

    renderModelLastPayment() {
        const {lastpaymentmodal} = this.state;
        return (<>
            <Modal
                isOpen={lastpaymentmodal}
                toggle={()=>{
                    this.setState({
                        lastpaymentmodal:false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <Button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={()=>{
                            this.setState({
                                lastpaymentmodal:false
                            })
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                    <h4 className="title title-up">LAST PAYMENT</h4>
                </div>
                <div className="modal-body">


                    <AvForm   className="form-horizontal" id="TypeValidation">

                        <div className='react-confirm-alert-body'>

                            <div className="nav-tabs-navigation">
                                <div className="nav-tabs-wrapper">

                                    <div style={{textAlign:"left"}}>
                                        As the project is coming to an end, you are invited to submit your final payment equal to 40% of the total project fees. FoodSight team will make sure to coordinate with you to make sure your payment is successfully received for final handover with the expert. You are then invited to mark the project complete
                                </div>
                                    <br/>
                                    <Nav id="tabs" role="tablist" tabs>
                                        <NavItem>
                                            <NavLink
                                                aria-expanded={this.state.horizontalTabs === "paymenttype1"}
                                                data-toggle="tab"
                                                href="#pablo"
                                                role="tab"
                                                className={
                                                    this.state.horizontalTabs === "paymenttype1"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ horizontalTabs: "paymenttype1",method:"Wire Transfer" })
                                                }
                                            >
                                                Wire Transfer
                                            </NavLink>
                                        </NavItem>


                                     <NavItem>
                                            <NavLink
                                                aria-expanded={
                                                    this.state.horizontalTabs === "paymenttype3"
                                                }
                                                data-toggle="tab"
                                                href="#pablo"
                                                role="tab"
                                                className={
                                                    this.state.horizontalTabs === "paymenttype3"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ horizontalTabs: "paymenttype3",method:"Physical Payment" })
                                                }
                                            >
                                                Physical Payment
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                aria-expanded={this.state.horizontalTabs === "paymenttype2"}
                                                data-toggle="tab"
                                                href="#pablo"
                                                role="tab"
                                                className={
                                                    this.state.horizontalTabs === "paymenttype2"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ horizontalTabs: "paymenttype2",method:"OMT" })
                                                }
                                            >
                                                OMT
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </div>
                            </div>

                            <TabContent
                                className="text-center"
                                id="my-tab-content"
                                activeTab={this.state.horizontalTabs}
                            >
                                <TabPane tabId="paymenttype1"  role="tabpanel">
                                    <p>
                                        <p>By choosing this option you agree to transfer the project fees to FoodSight bank account, following the details below:
                                        </p>
                                        <ul>
                                            <li>
                                                Beneficiary name: FoodSight SAL
                                            </li>
                                            <li>
                                                Bank Name: Arab Bank
                                            </li>
                                            <li>
                                                Bank account Number: 0338-774559-800
                                            </li>
                                            <li>
                                                IBAN: LB71 0005 0000 0000 3387 7455 9800
                                            </li>
                                            <li>
                                                Account Currency: LBP
                                            </li>
                                            <li>
                                                Swift code: ARABLBBX
                                            </li>


                                        </ul>
                                        <hr/>
                                        <ul>
                                            <li>Bank Account Number: 0338-774559-922</li>
                                            <li>IBAN LB75 0005 0000 0000 3387 7455 9922 </li>
                                            <li>Account Currency: USD </li>
                                        </ul>
                                    </p>
                                </TabPane>

                                <TabPane tabId="paymenttype3"  role="tabpanel">
                                    <p>
                                        Choosing this option will require passing by our offices or meeting us at your offices, to submit your payment in-person, either in Cash or in bankers Cheque </p>
                                </TabPane>

                                <TabPane tabId="paymenttype2"  role="tabpanel">
                                    <p>
                                        By choosing this option you agree to transfer the project fees to our OMT account, to one of the following accounts:
                                        <ul>
                                            <li>Fidele Mekhayel El Achkar +96170910632</li>
                                            <li>Nathalie Abdo Nasr +96170099770</li>
                                        </ul>
                                    </p>
                                </TabPane>
                            </TabContent>

                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            // className="btn-link"
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    lastpaymentmodal:false
                                })

                                this.makelastpayment();
                            }}
                        >
                            Ok
                        </Button>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    lastpaymentmodal:false,
                                })
                            }}
                        >
                            Cancel
                        </Button>



                    </div>

                </div>
            </Modal>
        </>)
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );

        const mielstoneid = this.props.milestone._id
        const response = await  this.engine.getItem("milestone","/info/"+mielstoneid);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                milestone:response.data.data.milestone,
                commentslist:response.data.data.commentslist,
                milestonedeliverables:response.data.data.milestonedeliverables,
                needtomakelastpayment:response.data.data.needtomakelastpayment,
                }
            );

            if(response.data.data.allmilestonedone){
                this.props.markascompleted()
            }
        }
        this.props.loadingAction(false);
    }


    async processmilestone(activestep,returntoexpert=false){
        const data={
            id:this.props.milestone._id,
            activestep:activestep,
            returntoexpert:returntoexpert
        }

        this.setState({
                loading: true,
            }
        );
        const response = await  this.engine.postItem("milestone","/process",data);
        if(response && response.status === 200){
            debugger
            this.setState({
                loading: false,
                milestone:response.data.data.milestone,
                },()=>{
                if(returntoexpert){
                    Utilites.showSucessMessage("Returned to expert!")
                }else
                if(activestep==1){
                    Utilites.showSucessMessage("Started!")
                }else
                if(activestep==2){
                    Utilites.showSucessMessage("Submitted!")
                }else
                if(activestep==3){
                    Utilites.showSucessMessage("Approved!")
                }


                this.callPage();
                   // this.props.history.push('/admin/request/archived');

                }
            );

            if(response.data.data.allmilestonedone){
                this.props.markascompleted()
            }
        }
       // this.props.loadingAction(false);
    }


    async processcomments(){

        if(this.state.comments.length<1 && this.idFileBase64Attach.length===0){
            Utilites.showErrorMessage("Please fill comment or attachmenet");
            return;
        }
        const data={
            id:this.props.milestone._id,
            comments:this.state.comments,
            attachements : this.idFileBase64Attach,
            projectid : this.props.projectid
        }

        this.setState({
            commentloading: true,
            }
        );
        const response = await  this.engine.postItem("milestone","/comment",data);
        if(response && response.status === 200){
            this.idFileBase64Attach = []
            this.setState({
                commentloading: false,
                comments:"",
                attachements:[],
                attachments_url:[],
                commentslist:response.data.data.comments,
                milestonedeliverables:response.data.data.milestonedeliverables,
                },()=>{
                    Utilites.showSucessMessage("Commented!")
                }
            );
        }
    }

    async processmilestonedelivery(){
        const data={
            id:this.props.milestone._id,
            attachements:this.idFileBase64Attachdelivery,
            projectid : this.props.projectid
        }

        this.setState({
                deliverablesloading: true,
            }
        );
        const response = await  this.engine.postItem("milestone","/deliverables",data);
        if(response && response.status === 200){
            this.idFileBase64Attachdelivery = []
            this.setState({
                deliverablesloading: false,
                    comments:"",
                    attachments_urldelivery:[],
                    attachements:[],
                    attachments_url:[],
                    commentslist:response.data.data.comments,
                    milestonedeliverables:response.data.data.milestonedeliverables,
                },()=>{
                    Utilites.showSucessMessage("Uploaded!")
                }
            );
        }
    }


    updateUploadedFiles(files){
        this.idFileBase64Attach = [];
        files.map(i=>{
            if(!i.url){
                Utilites.getFileBase64(i, (result) => {
                    this.idFileBase64Attach.push({base64:result,name:i.name});
                });
            }else{
                this.idFileBase64Attach.push(i);
            }
        })
    }

    updateUploadedFilesdelivery(files){
        this.idFileBase64Attachdelivery = [];
        files.map(i=>{
            if(!i.url){
                Utilites.getFileBase64(i, (result) => {
                    this.idFileBase64Attachdelivery.push({base64:result,name:i.name});
                });
            }else{
                this.idFileBase64Attachdelivery.push(i);
            }
        })
    }




    render() {


        const {needtomakelastpayment,milestonedeliverables,isLoading,attachments_url,deliverablesloading,attachments_urldelivery,commentloading,loading,milestone,comments,commentslist }= this.state;
        const {readonly,number,usertype,currency,islastmilestone,index} = this.props;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <Card>
                {this.renderModelLastPayment()}

                <Stepper
                    steps={[{ label: 'To Do' }, { label: 'In Progress' }, { label: 'Client Validation' },{ label: 'Done' }]}
                    activeStep={milestone.activestep}
                    ConnectorStyleProps={{
                        activeColor:"#3E50B5"
                    }}
                    activeColor={"#3E50B5"}
                    completedColor={"#3E50B5"}
                />
                <CardHeader>
                    <CardTitle tag="h5" style={{fontWeight:"bold"}} className={"boldmin boldx"}>Milestone {number}</CardTitle>
                    <Row>
                        <Col md="4">
                            <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Duration (Days): <span style={{fontWeight:"normal"}}>{milestone.duration}</span></CardTitle>
                        </Col>
                        <Col md="4">
                            <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Cost ({currency}): <span style={{fontWeight:"normal"}}>{milestone.cost}</span></CardTitle>
                        </Col>

                    </Row>
                    <Row>

                        <Col md="12">
                          <CardTitle tag="h6"  style={{fontWeight:"bold"}}>Description </CardTitle>

                            <CardTitle  style={{fontWeight:"normal",marginTop:20}}>
                                <LoadMore description={milestone.description} />
                            </CardTitle>
                        </Col>
                    </Row>

                    <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">

                        {commentslist && commentslist.length>0 && <CardTitle tag="h6"  style={{fontWeight:"bold"}}>Comments : </CardTitle>}

                        {commentslist && commentslist.map(i=>{
                            return  <div>
                                <div>
                                   <b>{i.user && i.user.first+" "+i.user.last}</b> <br/>
                                    {i.text}
                                    <br/>
                                    <i>{this.renderDate(i.createdAt)}</i>
                                </div>
                                <br/>
                                {i.attachments_url.length>0 && <ul style={{paddingLeft: 13}}>
                                    {i.attachments_url.map((ii,index)=>{
                                        return <li key={index}><a target={"_blank"} href={ii.url}>{ii.name}</a> </li>
                                    })}
                                </ul>}
                                <hr/>
                            </div>
                        })}



                        {!readonly && milestone.activestep>0 && milestone.activestep<4 && <Row className={"m-2"}>

                        <Col md="10">
                            <FormRow label="Comments" type={"textarea"} required={false} name="comments" data={comments}   changeInput={this.changeInput.bind(this)} />

                            <div style={{    width: 100,
                                position: "relative",
                                float: "right",
                                marginTop: -50,
                                textAlign: "right"}} onClick={()=>{
                                document.getElementById("filemin"+index).click()
                            }}>
                                <i className="nc-icon nc-cloud-upload-94" style={{fontSize: 20,
                                    cursor: "pointer"}} />
                            </div>
                            <div>
                                <FileUpload
                                    accept=".jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx"
                                    lite={true}
                                    hideicon={true}
                                    index={index}
                                    multiple
                                    updateFilesCb={this.updateUploadedFiles.bind(this)}
                                    oldvalue={attachments_url}
                                />
                            </div>

                        </Col>

                         <Col md="2" style={{marginTop:30}}>
                                <ButtonLoader  onClick={()=>{
                                    this.processcomments();
                                }}   className="pull-right emptybtn" color="secondary" loading={commentloading}>Send</ButtonLoader>

                            </Col>




                    </Row>}



                        {usertype=="expert" && milestonedeliverables && milestonedeliverables.length>0  &&<div>Milestone deliverables</div>}
                        {usertype=="expert" && milestonedeliverables && milestonedeliverables.length>0 && milestonedeliverables.map(i=>{
                                return i.attachments_url.map((ii,index)=>{
                                    return <li key={index}><a target={"_blank"} href={ii.url}>{ii.name}</a> </li>
                                })
                        })}


                        {usertype=="client" &&  milestonedeliverables && milestonedeliverables.length>0  &&<div>Milestone deliverables</div>}
                        {usertype=="client" &&  milestonedeliverables && milestonedeliverables.length>0 && milestonedeliverables.map(i=>{
                            return i.attachments_url.map((ii,index)=>{
                                if(islastmilestone && needtomakelastpayment!=2){
                                    return <li key={index}><a onClick={()=>{
                                        if(needtomakelastpayment==1){
                                            Utilites.showErrorMessage("Pending Payment Validation")
                                        }else{
                                            Utilites.showErrorMessage("Please make your last payment")
                                        }

                                    }} href={"#"}>{ii.name}</a> </li>
                                }
                                return <li key={index}><a target={"_blank"} href={ii.url}>{ii.name}</a> </li>
                            })
                        })}

                        {islastmilestone && usertype=="client" && needtomakelastpayment==0 &&  <Button onClick={()=>{
                            this.setState({
                                lastpaymentmodal:true,
                            })
                        }}

                        >Make Last Payment</Button>}
                        {usertype=="client" && needtomakelastpayment==1 &&  <span >Pending Payment Validation</span>}


                        {!readonly && milestone.activestep>0 && usertype=="expert" && <Row>
                            <Col sm="12">
                                <FileUpload
                                    accept=".jpg,.png,.jpeg,.pdf,.doc,.docx,.xls,.xlsx"
                                    label="Milestone deliverables"
                                    multiple
                                    updateFilesCb={this.updateUploadedFilesdelivery.bind(this)}
                                    oldvalue={attachments_urldelivery}
                                />

                                <ButtonLoader  onClick={()=>{
                                    this.processmilestonedelivery();
                                }}   className="pull-right emptybtn" color="secondary" loading={deliverablesloading}>Upload</ButtonLoader>
                            </Col>
                        </Row>}
                    </AvForm>


                    <div className="pull-right floatbtn" style={{width:390,padding:10}}>

                        {usertype=="expert" && milestone.activestep==0 && <ButtonLoader onClick={()=>{
                           this.processmilestone(1);
                        }}   className="pull-right" color="primary" loading={loading}>Start</ButtonLoader>}

                        {usertype=="expert" && milestone.activestep==1 && <ButtonLoader onClick={()=>{
                            this.processmilestone(2);
                        }}   className="pull-right" color="primary" loading={loading}>Submit for review</ButtonLoader>}

                        {usertype=="client" && milestone.activestep==2 && <ButtonLoader onClick={()=>{
                            this.processmilestone(3);
                        }}   className="pull-right" color="primary" loading={loading}>Approve</ButtonLoader>}

                        {usertype=="client" && milestone.activestep==2 && <ButtonLoader onClick={()=>{
                            this.processmilestone(1,true);
                        }}   className="pull-right" color="primary" loading={loading}>Return to expert</ButtonLoader>}




                    </div>
                </CardHeader>


            </Card>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ProjectMileStones);

