import React from "react";

// reactstrap misc
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";
import blacklogo from "assets/img/logo.png";
import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import {AvForm} from "availity-reactstrap-validation";
import {Utilites} from "../../core/Utilites";

class ChangePassword extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            password:"",
            password2:"",
            token : "",
            loading : false,
        };
        this.engine = new RequestEngine();
    }


    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    componentDidMount() {

        document.body.classList.toggle("login-page");
        const hash = this.props.match.params.hash
        this.setState({
            token:hash
        })
    }




    async resetpass(){


        const {password2,password} = this.state

        if(password!=password2){
            Utilites.showErrorMessage("Password Not Match");
            return
        }
        this.setState({
            loading: true
        })

        try {
            const response = await this.engine.postItem("user","/reset",this.state)
            if(response && response.status === 200){

                if(response.data.success){

                    Utilites.showSucessMessage("Password Changed")
                }else{
                    Utilites.showErrorMessage(response.data.message)
                }
            }
        } catch (e) {
            Utilites.showErrorMessage("error please try again")
        }

        this.setState({
            loading: false
        })
    }




    render() {
        const {password2,password,loading} = this.state
        return (
            <div className="login-page">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="8" md="8">
                            <Card className="card-login" style={{marginTop:100,padding:30}}>
                                <CardBody>

                                    <Row >
                                        <Col md="6">
                                            <h5 className={"bold"}>Change Password</h5>
                                        </Col>
                                    </Row>
                                    <hr/>

                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-key-25" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="off"
                                            value={password}
                                            onKeyPressCapture={event => {
                                                if(event.charCode == 13){
                                                    this.resetpass()
                                                }
                                            }}
                                            onChange={e =>
                                                this.changeInput(e, "password")
                                            }
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-key-25" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Retype Password"
                                            type="password"
                                            autoComplete="off"
                                            value={password2}
                                            onKeyPressCapture={event => {
                                                if(event.charCode == 13){
                                                    this.resetpass()
                                                }
                                            }}
                                            onChange={e =>
                                                this.changeInput(e, "password2")
                                            }
                                        />
                                    </InputGroup>
                                    <br />


                                </CardBody>

                                <div className={"centrino"}>
                                    <ButtonLoader className=" mb-3" onClick={() => this.resetpass()}   style={{width: "95%",
                                        margin: "auto"}} color="primary" loading={loading}>Update</ButtonLoader>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"

                />
            </div>
        );
    }
}

export default ChangePassword;
