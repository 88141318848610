import React from "react";
// javascript plugin used to create scrollbars on windows

import { Route, Switch } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";

import routes from "../../routes.js";
import {Card, CardHeader, CardTitle, Col, Row} from "reactstrap";
import blacklogo from "../../assets/img/logo.png";

class Pages extends React.Component {

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
        <div className="wrapper " ref="fullPages" style={{backgroundColor:"white"}}>


          <Row className={"h-100"}>
            <Col md="3" style={{backgroundColor:"#3F50B5",padding:50}} >

                  <img src={blacklogo} alt="react-logo" style={{width:120}} className="innerLogo" />
                  <div className="align-middle " style={{marginTop:200}}>
                    <CardTitle tag="h2" style={{color:"white",fontWeight:"bold"}} >FoodSight</CardTitle>
                    <CardTitle tag="h6" style={{color:"white"}} >Please sign in or create a new account to continue</CardTitle>

                      <div style={{color:"white"}} className={"mt-5"}>
                          “Signing up through this page makes you officially registered on this platform, and allows you to request services from our top freelance experts. It also provides you with a digitized work environment to initiate, implement and track your project.

                          If you already signed up on either our e-library or Toolkit pages, you can just sign in/login here”
                      </div>
                  </div>

            </Col>

            <Col md="9">
                <Switch>{this.getRoutes(routes)}</Switch>
            </Col>
          </Row>
        </div>
    );
  }
}

export default Pages;
