import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Label,
    Col, Button
} from "reactstrap";
import {categorylist} from "../../core/staticdata";


import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import SelectRow from "../../components/Select/SelectRow";


import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import FileUpload from "../../components/file-upload/file-upload.component";
import {Utilites} from "../../core/Utilites";
import {selectCountryList} from "../../core/countries";

export default class RequestMiniInformationAdv extends CoreEngine {
    constructor(props) {
        super(props);


        this.state = {
            isLoading: false,
            title:"",
            outcome:"",
            expertise:"",
            tradeprojectother:"",
            budget:"",
            deadline:"",
            duration:"",
            comments:"",
            category:"",
            othercategory:"",
            randdprojectstatusother:"",
            certificationexpectedother:"",
            otherranddtype:"",
            othertraderequest:"",
            currency:"USD",
            _id:"",
            loading : false,
            attachements:[],
            attachments_url:[],
            startdate:"",
            randdtype:"",
            randdprojectstatus:"",
            randdlevel:"",
            randdchallenge:"",
            fsmstype:"",
            fsmslevel:"",
            fsmschallenge:"",
            fsmsmeasure:"",
            fsmsdoc:"",
            certificationtype:"",
            certificationlevel:"",
            certificationchallenge:"",
            certificationmeasure:"",
            certificationdoc:"",
            certificationexpected:"",
            tradetype:"",
            tradecountry:"",
            tradetime:"",
            traderequest:"",
            tradeactivities:"",
            tradeproject:"",
            trainingtype:"",
            trainingtopic:"",
            trainingnumber:"",
            trainingwork:"",
            traininglevel:"",
            trainingpreivous:"",
            trainingtooltip:"",
            trainingtimeline:"",
            trainingdaytime:"",
            traininglocation:"",
            traininglocationother:"",

            randdchallengeyes:"",
            fsmsdocyes:"",
            fsmschallengeyes:"",
            certificationchallengeyes:"",
            certificationdocyes:"",
            trainingpreivousyes:""
        };

    }

    renderRandD() {

        const {
            randdchallengeyes,
            otherranddtype,
            randdtype,
            randdprojectstatusother,
            randdprojectstatus,
            randdlevel,
            randdchallenge}= this.props.data;
        return (<div>
                    <SelectRow viewonly={true} label="Type of optimization requested"  required={false}   name="randdtype"  className={"mr-2"} defaultValue={randdtype}  data={this.createSelectValue(["shelf life/quality/packaging optimization of existing product","new product development ","process line optimization ","trials","Other"])} changeInput={this.changeInput.bind(this)} />
                    {randdtype=="Other" && <FormRow viewonly={true} label="Please sepcify"  name="otherranddtype" data={otherranddtype} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                    <SelectRow label="Project status "   viewonly={true} required={false}   name="randdprojectstatus"  className={"mr-2"} defaultValue={randdprojectstatus}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the research/trials/optimization "])} changeInput={this.changeInput.bind(this)} />
                    {randdprojectstatus.includes("started") && <FormRow viewonly={true} label="Please specify what activities have you implemented"  name="randdprojectstatusother" data={randdprojectstatusother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                    <FormRow label="High level process steps & conditions" viewonly={true}  type={"textarea"} name="randdlevel" data={randdlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Challenge/issue/problem faced"  viewonly={true} required={false}   name="randdchallenge"  className={"mr-2"} defaultValue={randdchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {randdchallenge=="yes" && <FormRow label="Please Write it here" viewonly={true}  name="randdchallengeyes" data={randdchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

        </div>)

    }
    renderFSMS() {

        const {
            fsmsdocyes,
            fsmschallengeyes,
            fsmstype,
            fsmslevel,
            fsmschallenge,
            fsmsmeasure,
            fsmsdoc,}= this.props.data;
        return (<div>

                    <FormRow label="Type of service"  viewonly={true} name="fsmstype" data={fsmstype}    />
                    <FormRow viewonly={true} label="High level process steps & conditions surrounding the production line"  type={"textarea"} name="fsmslevel" data={fsmslevel}    />

                    <SelectRow label="Challenge/issue/problem faced"  viewonly={true} required={false}   name="fsmschallenge"  className={"mr-2"} defaultValue={fsmschallenge}  data={this.createSelectValue(["yes","no"])} />
                    {fsmschallenge=="yes" && <FormRow label="Please Write it here" viewonly={true}  name="fsmschallengeyes" data={fsmschallengeyes} className={"mr-2"}     />}
                    <FormRow label="Current measures taken"  viewonly={true} name="fsmsmeasure" data={fsmsmeasure}     />
                    <SelectRow label="Documentation in place"  viewonly={true} required={false}   name="fsmsdoc"   defaultValue={fsmsdoc}  data={this.createSelectValue(["yes","no"])}  />
                    {fsmsdoc=="yes" && <FormRow label="Please Write it here"  viewonly={true} name="fsmsdocyes" data={fsmsdocyes}      />}
        </div>)

    }
    renderCERTIFICATION() {

        const {
            certificationdocyes,
            certificationchallengeyes,
            certificationtype,
            certificationlevel,
            certificationchallenge,
            certificationexpectedother,
            certificationmeasure,
            certificationdoc,
            certificationexpected,}= this.props.data;
        return (<div>
                    <SelectRow label="Type of service" viewonly={true}  required={false}   name="certificationtype"  className={"mr-2"} defaultValue={certificationtype}  data={this.createSelectValue(["gap analysis"," pre-certification","pre-audit "])} changeInput={this.changeInput.bind(this)} />

                    <FormRow label="High level process steps & conditions" viewonly={true} type={"textarea"} name="certificationlevel" data={certificationlevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />


                    <SelectRow label="Challenge/issue/problem faced" viewonly={true}  required={false}   name="certificationchallenge"  className={"mr-2"} defaultValue={certificationchallenge}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {certificationchallenge=="yes" && <FormRow label="Please Write it here" viewonly={true}  name="certificationchallengeyes" data={certificationchallengeyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


                    <FormRow label="Current measures taken" viewonly={true} name="certificationmeasure" data={certificationmeasure} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Documentation in place"  viewonly={true} required={false}   name="certificationdoc"  className={"mr-2"} defaultValue={certificationdoc}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {certificationdoc=="yes" && <FormRow viewonly={true} label="Please Write it here"  name="certificationdocyes" data={certificationdocyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}

                    <SelectRow label="Certification expected "  viewonly={true} required={false}   name="certificationexpected"  className={"mr-2"} defaultValue={certificationexpected}  data={this.createSelectValue(["HACCP","ISO22000","FSSC22000","ISO9001","BRC","Global GAP"," Organic certification","Other"])} changeInput={this.changeInput.bind(this)} />

                    {certificationexpected=="Other" &&
                    <FormRow label="Please sepcify"  viewonly={true} name="certificationexpectedother" data={certificationexpectedother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }

        </div>)

    }
    renderTrade() {

        const {
            othertraderequest,
            tradetype,
            tradecountry,
            tradetime,
            traderequest,
            tradeactivities,
            tradeproject,tradeprojectother}= this.props.data;
        return (<div>
                    <SelectRow label="Type of service" viewonly={true}  required={false}   name="tradetype"  className={"mr-2"} defaultValue={tradetype}  data={this.createSelectValue(["import","export"])} changeInput={this.changeInput.bind(this)} />
                    <SelectRow label="Country"  viewonly={true} required={false}   name="tradecountry"  className={"mr-2"} defaultValue={tradecountry}  data={selectCountryList}  />
                    <SelectRow label="Time in trade" viewonly={true}  required={false}   name="tradetime"  className={"mr-2"} defaultValue={tradetime}  data={this.createSelectValue(["1st time importing / exporting","new import /export activities or products"])} changeInput={this.changeInput.bind(this)} />
                    <SelectRow label="Request" viewonly={true} required={false}   name="traderequest"  className={"mr-2"} defaultValue={traderequest}  data={this.createSelectValue(["food safety & quality regulations compliance (FDA, EU, CFIA, GSO..."," label creation","label review","packaging compliance","Other"])} changeInput={this.changeInput.bind(this)} />
                    {traderequest=="Other" &&
                    <FormRow label="Please sepcify" viewonly={true} name="othertraderequest" data={othertraderequest} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }

                    <FormRow label="Activities/Products"  viewonly={true} name="tradeactivities" data={tradeactivities} className={"mr-2"} type={"textarea"}    changeInput={this.changeInput.bind(this)} />
                    <SelectRow label="Project status" viewonly={true} required={false}   name="tradeproject"  className={"mr-2"} defaultValue={tradeproject}  data={this.createSelectValue(["we did not start with any step yet","we started few steps of the compliance/labelling/packaging"])} changeInput={this.changeInput.bind(this)} />
                    {tradeproject.includes("started") &&
                    <FormRow viewonly={true} label="Please specify what activities have you implemented"  name="tradeprojectother" data={tradeprojectother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />
                    }


        </div>)

    }
    renderTraining() {

        const {
            trainingpreivousyes,
            trainingtype,
            trainingtopic,
            trainingnumber,
            trainingwork,
            traininglevel,
            trainingpreivous,
            trainingtooltip,
            trainingtimeline,
            trainingdaytime,
            traininglocation,traininglocationother}= this.props.data;
        return (<div>
                    <SelectRow label="Type of training" viewonly={true}  required={false}   name="trainingtype"  className={"mr-2"} defaultValue={trainingtype}  data={this.createSelectValue(["Individual","company","cluster/group"])} changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Topic of training"  viewonly={true} name="trainingtopic" data={trainingtopic} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Number of trainees" viewonly={true} type={"number"}  name="trainingnumber" data={trainingnumber} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Type of work the trainees perform"  viewonly={true} name="trainingwork" data={trainingwork} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <FormRow label="Level of knowledge of the trainees (are they new at work ?)" viewonly={true} name="traininglevel" data={traininglevel} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Previous training received"  required={false} viewonly={true}  name="trainingpreivous"  className={"mr-2"} defaultValue={trainingpreivous}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                    {trainingpreivous=="yes" && <FormRow label="Please Write it here" viewonly={true} name="trainingpreivousyes" data={trainingpreivousyes} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


                    <FormRow label="Specific consideration"  name="trainingtooltip" viewonly={true} data={trainingtooltip} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Timeline"  required={false} viewonly={true}  name="trainingtimeline"  className={"mr-2"} defaultValue={trainingtimeline}  data={this.createSelectValue(["one-time training","recurrent training "])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="day time"  required={false} viewonly={true}  name="trainingdaytime"  className={"mr-2"} defaultValue={trainingdaytime}  data={this.createSelectValue(["am","pm"])} changeInput={this.changeInput.bind(this)} />

                    <SelectRow label="Location to conduct the training"  viewonly={true} required={false}   name="traininglocation"  className={"mr-2"} defaultValue={traininglocation}  data={this.createSelectValue(["online","in person"])} changeInput={this.changeInput.bind(this)} />
                    {traininglocation=="in person" && <FormRow label="Please specify the location" viewonly={true}  name="traininglocationother" data={traininglocationother} className={"mr-2"}    changeInput={this.changeInput.bind(this)} />}


        </div>)

    }
    render() {
        const {loading,isLoading, title,
            expertise,
            budget,
            duration,
            comments,

            othercategory,
            outcome,
            category,attachments_url,currency,deadline ,startdate}= this.props.data;


        return (
            <>
                    <Row>
                        <Col md="12">

                            <AvForm    >

                                        {category && category.includes("search") && this.renderRandD()}
                                        {category && category.includes("ocumentation") && this.renderFSMS()}
                                        {category && category.includes("ertification") && this.renderCERTIFICATION()}
                                        {category && category.includes("ssistance") && this.renderTrade()}
                                        {category && category.includes("aining") && this.renderTraining()}

                                                <SelectRow viewonly={true} label="Expertise"  info={"What expertise level suits you"}  name="expertise"  defaultValue={expertise}  data={this.createSelectValue(["Fresh Graduate","Junior (3-5 years of experience)","Senior (5-10 years of experience)","Managerial (10+ years of experience)"])} changeInput={this.changeInput.bind(this)} />
                                                <FormRow viewonly={true} label="Outcome / Result Expected" required={false} type={"textarea"} name="outcome" data={outcome}   changeInput={this.changeInput.bind(this)} />
                                                <FormRow viewonly={true} label="Additional Description or Comments" required={false} type={"textarea"} name="comments" data={comments}   changeInput={this.changeInput.bind(this)} />

                            </AvForm>

                        </Col>
                    </Row>

            </>
        );
    }
}


