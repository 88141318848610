import React from "react";


import {sectoroperatorin,titlelist} from "../../core/staticdata";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup, Modal, Button, Label
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import { withRouter} from "react-router-dom";
import startsWith from 'lodash.startswith';
import 'react-phone-input-2/lib/style.css'
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import ImageUpload from "../../components/CustomUpload/ImageUpload";

import {Utilites} from "../../core/Utilites";
import SelectRow from "../../components/Select/SelectRow";
import {selectCountryList} from "../../core/countries";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import {Stepper} from "react-form-stepper";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "react-phone-number-input";


class ClientAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            allowdelete:false,
            title:"",
            first:"",
            last:"",
            email:"",
            password:"",
            birthday:"",
            gender:"",
            major:"",
            company:"",
            country:"",
            interestedin:"",
            educational:"",
            phone:"",
            expertise:"",
            showcountries:false,
            projectworkbefore:"",
            systemexperiencewith:"",



            mdf:null,
            fullmdf:"",
            commercial:null,
            fullcommercial:"",

            cv:null,
            picture:null,
            filename:"",
            fullcv:"",
            fullpicture:"",
            password2:"",
            employmentstatus:"",
            loading:false,
            loadingdelete:false,
            showdelete:false,
            showother:false,
            sectors:[],
            othersector:"",

            city:"",
            district:"",
            street:"",
            buildingname:"",
            banka_benfname:"",
            banka_iban:"",
            banka_number:"",
            banka_name:"",
            workphonerror:"",
            banka_swift:"",
            witchcountry:[],
            step:0,



            numberofyears:"",
            numberofemployees:"",
            capcacitysize:"",
            producttype:"",
            areyouexporting:"no",
            disgnatedperson:"",
            anycertificationinsurance:"",
            nameofcertification:"",






        };
        this.engine = new RequestEngine();
        this.idFileBase64 = "";
        this.idFileBase64picture="";


        this.idFileBase64mdf = "";
        this.idFileBase64commercial = "";
    }



    componentDidMount() {
        this.callPage()
    }


    async callPage() {
        this.setState({
                isLoading: true,
            }
        );
        const response = await  this.engine.getItem("user","/me");

        if(response && response.status === 200){

            debugger

            this.setState({
                    isLoading: false,
                    ...response.data.data,

                showcountries:  response.data.data.areyouexporting.includes("yes")

                }
            );
        }
    }

    async updateUser(){
        try {


            const {showcountries,witchcountry} = this.state
            if(showcountries && witchcountry.length==0){
                Utilites.showErrorMessage("Please select which countries ")
                return;
            }
            this.setState({loading : true})
            let data = {...this.state};

            data.cvbase64 = this.idFileBase64;
            data.picturebase64 = this.idFileBase64picture;
            data.mdfbase64 = this.idFileBase64mdf;
            data.commercialbase64 = this.idFileBase64commercial;

            const result = await this.engine.postItem("user","/updateprofile",data)
            if(result.status === 200 && result.data.success){
                Utilites.showSucessMessage("Profile Updated")
                localStorage.setItem('picture', result.data.data.picture);
                const usertype  = localStorage.getItem('usertype');

                localStorage.removeItem("firstlogin");


                    this.props.history.push('/admin/request/list');

                window.location.reload()
            }else{
                Utilites.showErrorMessage(result.data.message);
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong registration info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }






    async sendDeleteRequest(){

        this.setState({
            loadingdelete:true
        })
        const response = await  this.engine.postItem("user","/delete");
        if(response && response.status === 200){
            this.setState({
                    loadingdelete: false,
                },()=>{
                localStorage.setItem('isloggedin', false);
                localStorage.setItem('managertype', null);
                localStorage.setItem('token', null);
                    // redirect to dashboard
                    this.props.history.push('/auth/login');
                }
            );
        }

    }





    renderModelDelete() {
        const {showdelete} = this.state;
        return (<>
            <Modal
                isOpen={showdelete}
                toggle={()=>{
                    this.setState({
                        showdelete:false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <Button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={()=>{
                            this.setState({
                                showdelete:false
                            })
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                    <h4 className="title title-up">Confirmation</h4>
                </div>
                <div className="modal-body">
                    <AvForm   className="form-horizontal" id="TypeValidation">
                        <div className='react-confirm-alert-body'>
                            <h6>Are you sure you want to delete ?</h6>
                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showdelete:false
                                })

                                this.sendDeleteRequest();
                            }}
                        >
                            Ok
                        </Button>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showdelete:false,
                                })
                            }}
                        >
                            Cancel
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }




    onChangeFile(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
        this.setState({cv:e.target.files[0],filename:e.target.files[0].name})
    }
    onChangeFilepic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64picture = result;
        });
        this.setState({picture:e.target.files[0]})
    }



    onChangeFilemdf(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64mdf = result;
        });
        this.setState({mdf:e.target.files[0]})
    }

    onChangeFilecommercial(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64commercial = result;
        });
        this.setState({commercial:e.target.files[0]})
    }



    renderStep1() {
        const {title,
            first,
            last,
            email,
            phone,
            loading,
            workphonerror,
            loadingdelete,
            allowdelete,
            city,
            district,
            street,
            buildingname,
            country,
            isLoading,
        } = this.state

        if(isLoading){
            return this.renderProgress();
        }
        return (
            <div className="content english">
                <Row>
                    <Col  lg="12" md="12">
                        <Card className="card-login" style={{marginTop:10,padding:30}}>
                            <AvForm   onValidSubmit={() => this.updateUser()}  className="form-horizontal" id="TypeValidation">
                                <CardHeader>
                                    <CardTitle tag="h4">Basic Information

                                        {allowdelete && <ButtonLoader className=" mb-3"  style={{float: "right",
                                            fontSize:13,
                                            width: 155}} onClick={() => {

                                            this.setState({
                                                showdelete:true
                                            })

                                        }}    color="danger" loading={loadingdelete}>DELETE</ButtonLoader>}

                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row style={{marginTop:10}}>
                                        <Col md="4"  >
                                            <SelectRow label="Title" className={"mr-2"} name="title"  defaultValue={title}  data={this.createSelectValue(titlelist)} changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="4"  >
                                            <FormRow label="Your First Name" className={"mr-2"} name="first" data={first}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="4" >
                                            <FormRow label="Your Last Name" name="last" data={last}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md="4" >
                                            <FormRow label="Your Email" readonly={true} className={"mr-2"} name="email" data={email} errorMessage={"Please write a valid email"} type={"email"}  changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="4" >

                                            <>
                                                <div>
                                                    <Label >Your Phone Number<span style={{color:"red"}}>*</span></Label>
                                                </div>
                                                <FormGroup >
                                                    <PhoneInput
                                                        country={'lb'}
                                                        value={phone}
                                                        inputProps={{
                                                        }}
                                                        isValid={(inputNumber, country, countries) => {
                                                            return countries.some((country) => {
                                                                return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                                            });
                                                        }}
                                                        onChange={(workhomephone) =>{
                                                            if(isValidPhoneNumber("+"+workhomephone) === true){
                                                                console.log("valid")
                                                                this.setState({
                                                                    workphonerror:""
                                                                })



                                                            }else{
                                                                console.log("invalid")

                                                                this.setState({
                                                                    workphonerror:"phone error"
                                                                })
                                                            }

                                                            this.setState({ phone :"+"+workhomephone})
                                                        } }
                                                    />

                                                </FormGroup>
                                                <div className={"phoneerror"}>{workphonerror}</div>
                                            </>
                                        </Col>

                                        <Col md="4" className={"centrino"} >
                                            <FormGroup >
                                                <ImageUpload filename={""} hideplaceholder={true} isfile={false} placeholder={this.state.fullpicture} labelupload="Select Profile Image" onChange={(e) => this.onChangeFilepic(e)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                </CardBody>
                                <CardHeader>
                                    <CardTitle tag="h4">Address Information</CardTitle>
                                </CardHeader>

                                <CardBody>

                                    <Row>

                                        <Col md="6" >
                                            <SelectRow label="Country"  required={true}   name="country"  className={"mr-2"} defaultValue={country}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />

                                        </Col>
                                        <Col md="6" >
                                            <FormRow label="City" required={true}  name="city" data={city}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="6" >
                                            <FormRow label="District" required={false}  name="district" data={district} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />

                                        </Col>
                                        <Col md="6" >
                                            <FormRow label="Street" required={false}  name="street" data={street}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" >
                                            <FormRow label="Building name" required={false}  name="buildingname" data={buildingname}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>



                                </CardBody>









                                <Row>
                                    <Col md="12">
                                        <ButtonLoader className=" mb-3"    style={{
                                            margin: "auto"}} color="primary" loading={loading}>Update</ButtonLoader>
                                        <Button className={"emptybtn"}  onClick={() => this.setState({
                                            step:1
                                        })} >Next</Button>
                                    </Col>
                                </Row>

                            </AvForm>

                        </Card>



                    </Col>
                </Row>


            </div>
        );

    }

    renderStep2() {
        const {numberofyears,
            numberofemployees,
            capcacitysize,
            producttype,
            areyouexporting,
            disgnatedperson,
            anycertificationinsurance,

            loading,
            loadingdelete,
            sectors,
            showother,
            othersector,
            allowdelete,
            nameofcertification,
            isLoading,
            showcountries,
            company, witchcountry,interestedin
        } = this.state

        if(isLoading){
            return this.renderProgress();
        }


        return (
            <div className="content english">
                <Row>
                    <Col  lg="12" md="12">
                        <Card className="card-login" style={{marginTop:10,padding:30}}>
                            <AvForm  onValidSubmit={() =>this.updateUser()} className="form-horizontal" id="TypeValidation">
                                <CardHeader>
                                    <CardTitle tag="h4">Complete Your Profile

                                        {allowdelete && <ButtonLoader className=" mb-3"  style={{float: "right",
                                            fontSize:13,
                                            width: 155}} onClick={() => {

                                            this.setState({
                                                showdelete:true
                                            })

                                        }}    color="danger" loading={loadingdelete}>DELETE</ButtonLoader>}

                                    </CardTitle>


                                </CardHeader>

                                <div className={"clear"}></div>

                                <CardBody>

                                    <Row>
                                        <Col md="6" >
                                            <FormRow label="Company name" className={"mr-2"} name="company" data={company}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" >
                                            <FormRow label="Number of years in business" className={"mr-2"} name="numberofyears" data={numberofyears}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                        <Col md="6" >
                                            <FormRow label="Number of employees" className={"mr-2"} name="numberofemployees" data={numberofemployees}   changeInput={this.changeInput.bind(this)} />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6" >
                                            <FormRow label="Monthly production capacity (in kg or product quantity)" type={"textarea"} className={"mr-2"} name="capcacitysize" data={capcacitysize}   changeInput={this.changeInput.bind(this)} />
                                        </Col>

                                        <Col md="6" className={"centrino"} >
                                            <FormGroup >
                                                <ImageUpload filename={"Mof"}  hideplaceholder={true} isfile={true} placeholder={this.state.fullmdf} labelupload="MOF certificate" onChange={(e) => this.onChangeFilemdf(e)}/>
                                            </FormGroup>
                                            <FormGroup >
                                                <ImageUpload filename={"Commercial"} hideplaceholder={true} isfile={true} placeholder={this.state.fullcommercial} labelupload="Commercial Registery" onChange={(e) => this.onChangeFilecommercial(e)}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" >
                                            <FormRow label="Product Types" type={"textarea"} className={"mr-2"} name="producttype" data={producttype}   changeInput={this.changeInput.bind(this)} />
                                        </Col>

                                        <Col md="6" >
                                            <SelectRow label="Are you Exporting ?" required={true}  name="areyouexporting"  className={"mr-2"} defaultValue={areyouexporting}  data={this.createSelectValue(["yes","no"])} changeInput={(e)=>{
                                                debugger
                                                this.setState({
                                                    areyouexporting:e.value,
                                                    showcountries:e.value.includes("yes")
                                                })
                                            }} />
                                            {showcountries && <SelectRow label="To which Countries" isMulti={true}  required={true}   name="witchcountry"  className={"mr-2"} defaultValue={witchcountry}  data={selectCountryList} changeInput={this.changeInput.bind(this)} />}
                                        </Col>
                                    </Row>


                                    <Row>


                                        <Col md="6" >
                                            <SelectRow label="Do you have a designated person for the quality department ?" required={true}  name="disgnatedperson"  className={"mr-2"} defaultValue={disgnatedperson}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                                        </Col>

                                        <Col md="6" >
                                            <SelectRow label="Do you have any certification or food safety & quality system in place ?" required={true}   name="anycertificationinsurance"  className={"mr-2"} defaultValue={anycertificationinsurance}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6"  >
                                            <SelectRow isMulti={true} label="in what sector do you operate in?" required={true} className={"mr-2"}  name="sectors"  defaultValue={sectors}  data={this.createSelectValue(sectoroperatorin)} changeInput={(e)=>{
                                                this.setState({ sectors: e })
                                                const otherfound = e.find(i=>i.value.toLowerCase()=="other");
                                                if(otherfound){
                                                    this.setState({
                                                        showother: true
                                                    });
                                                }else{
                                                    this.setState({
                                                        showother: false
                                                    });
                                                }
                                            }} />


                                            {showother &&
                                                    <FormRow label="Other" name="othersector" data={othersector} className={"mr-2"}   changeInput={this.changeInput.bind(this)} />
                                                }
                                        </Col>


                                        <Col md="6" >
                                            {anycertificationinsurance.includes("yes") && <FormRow label="Name the certification and the issuance dates" type={"textarea"} className={"mr-2"} name="nameofcertification" data={nameofcertification}   changeInput={this.changeInput.bind(this)} />}
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col md="12" >
                                            <FormRow label="What are your current company challenges and what are you interest in ?" type={"textarea"} name="interestedin" data={interestedin}   changeInput={this.changeInput.bind(this)} />
                                        </Col>
                                    </Row>





                                </CardBody>



                                <Row>
                                    <Col md="12">
                                        <ButtonLoader className=" mb-3"    style={{
                                            margin: "auto"}} color="primary" loading={loading}>Update</ButtonLoader>
                                        <Button className={"emptybtn"}  onClick={() => this.setState({
                                            step:0
                                        })} >Back</Button>
                                    </Col>
                                </Row>
                            </AvForm>

                        </Card>



                    </Col>
                </Row>


            </div>
        );

    }
    render() {
        const {
            isLoading,
            step
        } = this.state

        if(isLoading){
            return this.renderProgress();
        }
        let firstlogin = localStorage.getItem("firstlogin");
        return (
            <div className="content english">

                {firstlogin && <div style={{textAlign:"center",color:"red",fontWeight:"bold",fontSize:20}}>Please Complete your profile</div>}
                {this.renderModelDelete()}

                <Stepper
                    steps={[{ label: 'Personal Information' }, { label: 'Company Profile' }]}
                    activeStep={step}
                    ConnectorStyleProps={{
                        activeColor:"#3E50B5"
                    }}

                    activeColor={"#3E50B5"}
                    completedColor={"#3E50B5"}
                />
                {step==0 ? this.renderStep1():this.renderStep2()}


            </div>
        );

    }
}


const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(withRouter(ClientAddUpdate));

