import React from "react";
import classnames from "classnames";
import { withAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container, Modal
} from "reactstrap";
import Constants from "../../core/Constants";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import {AvForm} from "availity-reactstrap-validation";
import FormRow from "../Row/FormRow";
import {Link} from "react-router-dom";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      unreadcounter :0,
      showmodel:false,
      notifications :[],
      message:"",
      subject:"",
      lastnotification:{},
      color: "navbar-transparent"
    };


    this.engine = new RequestEngine();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);

    this.fetchnotification()
  }

  async checkunreadmsg(){

  const {notifications} = this.state
    const countercheck = notifications.filter(i=>i.status==0)
    this.setState({
      unreadcounter:countercheck.length>9?"9+":countercheck.length
    })
  }

  async fetchnotification(){

    const {lastnotification} = this.state
    const response = await  this.engine.getItem("notification");
    if(response && response.status === 200){

      //
      const possiblenewnotification = response.data.data.length>0 ? response.data.data[0]:{}
      if(possiblenewnotification && possiblenewnotification.status==0 && possiblenewnotification._id && !possiblenewnotification.silent && lastnotification && ((lastnotification._id && lastnotification._id!=possiblenewnotification._id) ||(!lastnotification._id && possiblenewnotification._id) )){
        //Utilites.showSucessMessage(possiblenewnotification.message,"New Notification!")
      }
      this.setState({
        notifications:response.data.data,
        lastnotification:possiblenewnotification
      },()=>{
        this.checkunreadmsg()
      })
    }

   // Utilites.setTimeout(()=>  this.fetchnotification(),4000)
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };


    handleLogout = () => {
      const { logout } = this.props.auth0;


        console.log("handleLogout")
        localStorage.setItem('isloggedin', false);
        localStorage.setItem('managertype', null);
        localStorage.setItem('token', null);
        localStorage.removeItem("firstlogin");

       logout({ returnTo: window.location.origin })
     // logout({ returnTo: '/auth/login' })


    };


  clearAll = async () => {

     await  this.engine.getItem("notification","/clearall");

    this.setState({
      unreadcounter :0,
      notifications :[],
      lastnotification:{},
    })
  }


  async sendContactRequest(){
    const {message,subject} = this.state
    const data={
      subject:subject,
      message:message
    }
    const response = await  this.engine.postItem("contact","/send",data);
    if(response && response.status === 200){
      this.setState({
            isLoading: false,
        showmodel:false
          },()=>{
            Utilites.showSucessMessage("Thank you")
          }
      );
    }
  }



  render() {
    const {notifications,unreadcounter} = this.state
    return (
      <>
        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >


          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                <span className="d-none d-md-block">

                </span>
                <span className="d-block d-md-none">{Constants.PROJECTTITLE} </span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
              <Nav navbar>
                <Button style={{marginTop: 5,marginRight: 11}} color="primary"
                    onClick={(e) =>   {
                      const usertype = localStorage.getItem('usertype');
                      if(usertype=="client"){
                        window.open("https://support.food-sight.com/portal/en/kb/foodsight-client-user-guide",'_blank')
                      }else{
                        window.open("https://support.food-sight.com/portal/en/kb/foodsight-expert-user-guide",'_blank')
                      }

                    }}
                >
                  Support
                </Button>
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                      aria-haspopup={true}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      id="navbarDropdownMenuLink2"
                      onClick={()=>{
                        this.setState({
                          unreadcounter:0
                        })
                        this.engine.getItem("notification","/read")
                      }}
                      nav
                  >
                    {unreadcounter>0 &&<div id={"badgex"}>
                      <span>
                        {unreadcounter}
                      </span>
                    </div>}
                    <i className="nc-icon nc-bell-55" />
                    <p>
                      <span className="d-lg-none d-md-block">Notifications</span>
                    </p>
                  </DropdownToggle>
                  {notifications.length==0 && <DropdownMenu aria-labelledby="navbarDropdownMenuLink2" right>
                        <DropdownItem  >You don't have any notification</DropdownItem>
                  </DropdownMenu>}
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink2" right>

                    {notifications.map((i,index)=>{
                      return  <DropdownItem key={index} ><Link to={i.link}>{i.message}</Link></DropdownItem>
                    })}

                    {notifications.length>0 && <Button style={{background:"white",fontSize:11,color:"blue",width:"100%"}} onClick={()=>{

                      this.clearAll()
                    }
                    }>Clear All</Button>}

                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-settings-gear-65" />
                    <p>
                      <span className="d-lg-none d-md-block">Settings</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                    <DropdownItem
                        onClick={(e) => this.props.history.push('/admin/myprofile')}
                    >
                      My Profile
                    </DropdownItem>
                    <DropdownItem
                        onClick={(e) => this.props.history.push('/admin/reset')}
                    >
                      Reset Password
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => this.handleLogout()}
                    >
                      Logout
                    </DropdownItem>

                  </DropdownMenu>
                </UncontrolledDropdown>


              </Nav>

            </Collapse>

          </Container>


        </Navbar>
      </>
    );
  }
}

export default withAuth0(AdminNavbar);
