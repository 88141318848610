import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col, Modal, Button
} from "reactstrap";
import {Utilites} from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import { AvForm } from 'availity-reactstrap-validation';
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import ProjectMileStones from "./ProjectMileStones";
import FormRow from "../../components/Row/FormRow";
import LoadMore from "../../core/LoadMore";
class ViewProject extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showmarkascompleted:false,
            request:{},
            offer:{},
            title:"",
            activatemarkcompleted:false,
            showcancel:false,
            cancelreason:"",
            description:"",
            expertise:"",
            budget:"",
            duration:"",
            comments:"",
            status:"",
            category:"",
            currency:"",
            awardedoffer:false,
            attachments_url:[],
            userid:"",
            user:"",
            _id:"",
            loading : false,
            offers:[],
             usertype : localStorage.getItem('usertype')
        };
        this.pathname = "project";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const userid = localStorage.getItem('userid');
        if(userid){
            this.setState({
                userid
            })
        }

        this.callPage()


    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );

        const requestid = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+requestid);

        if(response && response.status === 200){

            debugger

            this.setState({
                    isLoading: false,
                    ...response.data.data.info,
                }
            );

            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
        this.props.loadingAction(false);
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };






    async cancelproject(){
        const id = this.props.match.params.id

        const {cancelreason} = this.state
        if(cancelreason.length<2){
            Utilites.showErrorMessage("Cancel Reason is required");
            return;
        }
        const data={
            project:id,
            cancelreason:cancelreason
        }
        const response = await  this.engine.postItem("project","/cancel",data);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                    this.props.history.push('/admin/archivedprojects');

                }
            );
        }
        this.props.loadingAction(false);
    }


    async completeproject(){
        const id = this.props.match.params.id

        const data={
            project:id,
        }
        this.setState({
                isLoading: true,
            });
        const response = await  this.engine.postItem("project","/complete",data);
        if(response.status === 200 && response.data.success){
            this.setState({
                    isLoading: false,
                },()=>{
                    this.props.history.push('/admin/archivedprojects');
                }
            );
        }else{
            this.setState({
                isLoading: false,
            })
            Utilites.showErrorMessage(response.data.message);
        }
        this.props.loadingAction(false);
    }



    renderModelCompleted() {
        const {showmarkascompleted} = this.state;
        return (<>
            <Modal
                isOpen={showmarkascompleted}
                toggle={()=>{
                    this.setState({
                        showmarkascompleted:false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <Button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={()=>{
                            this.setState({
                                showmarkascompleted:false
                            })
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                    <h4 className="title title-up">Responsability Release Note</h4>
                </div>
                <div className="modal-body">


                    <AvForm   className="form-horizontal" id="TypeValidation">

                        <div className='react-confirm-alert-body'>
                            <p> Marking this project as complete is considered as a release from you to FoodSight and the Expert and an acknowledgment of receiving all information and documents under the Project approved scope of work.</p>
                            <p>Thank you for trusting FoodSight.
                            We look forward to more projects together!</p>
                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showmarkascompleted:false
                                })

                                this.completeproject();
                            }}
                        >
                            Accept
                        </Button>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showmarkascompleted:false,
                                })
                            }}
                        >
                            Cancel
                        </Button>



                    </div>

                </div>
            </Modal>
        </>)
    }
    renderModel() {
        const {showcancel,cancelreason} = this.state;
        return (<>
            <Modal
                isOpen={showcancel}
                toggle={()=>{
                    this.setState({
                        showcancel:false
                    })
                }}
            >
                <div className="modal-header justify-content-center">
                    <Button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={()=>{
                            this.setState({
                                showcancel:false
                            })
                        }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                    <h4 className="title title-up">Confirmation</h4>
                </div>
                <div className="modal-body">


                    <AvForm   className="form-horizontal" id="TypeValidation">

                        <div className='react-confirm-alert-body'>
                            <h6>You are canceling a project, are you sure?</h6>
                            <FormRow placeholder="Reason for cancel" name="cancelreason" data={cancelreason}   changeInput={this.changeInput.bind(this)} />


                        </div>
                    </AvForm>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            // className="btn-link"
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showcancel:false
                                })

                                this.cancelproject();
                            }}
                        >
                            Ok
                        </Button>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={()=>{
                                this.setState({
                                    showcancel:false,
                                })
                            }}
                        >
                            Cancel
                        </Button>



                    </div>

                </div>
            </Modal>
        </>)
    }


    markascompleted(){

        this.setState({
            activatemarkcompleted:true
        })
    }
    render() {
        // taking all the states
        const {isLoading, usertype,request,offer,status,activatemarkcompleted }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }

        let readonly = false;
        if(status=="archived" || status=="canceled" || status=="completed" ){
            readonly = true
        }
        return (
            <>
                <div className="content english">

                    {this.renderModel()}
                    {this.renderModelCompleted()}
                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm   className="form-horizontal" id="TypeValidation">

                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h5" style={{fontWeight:"bold"}} className={"boldmin boldx"}>{request.title} </CardTitle>
                                        <LoadMore description={request.description} />
                                    </CardHeader>
                                </Card>


                                <Row>
                                    <Col md="8">

                                        {offer.milestone && offer.milestone.map((item,index)=>{
                                            return (<ProjectMileStones projectid={this.props.match.params.id} index={index} islastmilestone={index==offer.milestone.length-1}  currency={offer && offer.currency} readonly={readonly} usertype={usertype} markascompleted={this.markascompleted.bind(this)} milestone={item} key={index} number={index+1} />)
                                        })}

                                        {usertype=="expert" && status=="completed" && <>
                                            <p>As the project is coming to an end, FoodSight team will coordinate with you to make sure your payment is successfully submitted, based on the payment method selected.</p>
                                            <p>  Thank you for your work!</p>
                                        </>}


                                    </Col>

                                    <Col md="4">
                                        <Card>
                                            <CardHeader>
                                                <div className="pull-right floatbtn" style={{width:190,padding:10}}>
                                                    <CardTitle tag="h6" className={"status"} >{status}</CardTitle>
                                                </div>
                                                <CardTitle tag="h5" style={{fontWeight:"bold"}} className={"boldmin boldx"}>Project Details</CardTitle>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Project Category  </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{request.category}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Project Expertise  </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{request.expertise}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Budget </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{offer.cost}</span>
                                                    </Col>
                                                </Row>

                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Duration (Days) </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{offer.duration}</span>
                                                    </Col>
                                                </Row>



                                                <Row style={{width: "100%"}}>
                                                    <Col md="6">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Additional Comments </CardTitle>
                                                    </Col>
                                                    <Col md="6" style={{marginTop:10}}>
                                                        <span style={{fontWeight:"normal",fontSize:13}}>{request.comments}</span>
                                                    </Col>
                                                </Row>
                                                <Row style={{width: "100%"}}>
                                                    <Col md="12">
                                                        <CardTitle tag="h6" className="pull-left" style={{fontWeight:"bold"}}>Attachements </CardTitle>
                                                    </Col>
                                                    <Col md="12">
                                                        <div>
                                                            {request.attachments_url && request.attachments_url.map((i,index)=>{
                                                                return <li key={index}><a target={"_blank"} href={i.url}>{i.name}</a> </li>
                                                            })}
                                                        </div>
                                                    </Col>

                                                </Row>



                                                {!activatemarkcompleted && status!="archived" && status!="canceled" && status!="completed" && usertype=="client" && <ButtonLoader onClick={()=>{
                                                    this.setState({
                                                        showcancel:true,
                                                    })
                                                }}   className="pull-right" color="primary">Cancel project</ButtonLoader>}

                                                {activatemarkcompleted && status!="archived" && status!="canceled"  && status!="completed" && usertype=="client"  && <ButtonLoader onClick={()=>{
                                                    this.setState({
                                                        showmarkascompleted:true,
                                                    })
                                                }}   className="pull-right" color="primary">Mark as completed</ButtonLoader>}


                                            </CardHeader>
                                        </Card>

                                    </Col>

                                </Row>


                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ViewProject);

