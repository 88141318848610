import React from "react";

// reactstrap misc
import {
  Card,
  CardBody,
  Container,
  Col,
  Row
} from "reactstrap";

import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";
import {AvForm} from "availity-reactstrap-validation";
import {Link} from "react-router-dom";
import {Utilites} from "../../core/Utilites";
import GApp from "../../App";

class Login extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password:"",
            password2:"",
            managertype : "",
           username :"",
            token : "",
            saml:false,
            loading : false,
            remember:1,
            shouldpick:false,
            init:true,
            showverifiederror:false,
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
       // localStorage.setItem('managertype', "admin");
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }


    async resendVerfication(){
        const {email,password} = this.state
        try {
            this.setState({loading : true})
            const result = await this.engine.resendlink(email,password)
            if(result.status === 200 && result.data.success){
            }else{
                this.setState({loading:false,showverifiederror:false})
                Utilites.showErrorMessage(result.data.message);
                this.setState({})
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong login info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }


    triggerlogin(user){

        const email = user.email;
        const sub = user.sub;

        this.setState({
            email:email,
            password:sub,
            saml:true,
        },()=>{
            this.login();
        })
        debugger
    }
    async login(){


        debugger
        let {email,password,saml} = this.state
        //email = "expert@yopmail.com";
        //password = "sa"
        if(!Utilites.validateEmail(email)){
            Utilites.showErrorMessage("Please write a valid email");
            return;
        }

        try {
            this.setState({loading : true})

            const result = await this.engine.login(email,password,saml)


            if(result.status === 200 && result.data.success){

                const verified = result.data.data.verified;
                if(!verified){
                    this.setState({loading:false,showverifiederror:true})
                    return;
                }
                const usertype = result.data.data.usertype.trim().toLowerCase();
                const token = result.data.data.token;
                const firstlogin = result.data.data.firstlogin;

                localStorage.setItem('usertype', usertype);
                localStorage.setItem('token', token);
                localStorage.setItem('userid', result.data.data.user._id);
                localStorage.setItem('firstname', result.data.data.firstname);
                localStorage.setItem('picture', result.data.data.user.fullpicture);
                localStorage.setItem('isloggedinfront', true);
                debugger
                if(firstlogin){
                    localStorage.setItem('firstlogin', firstlogin);
                    this.props.history.push('/admin/myprofile');
                }else{
                    // redirect to dashboard
                    if(usertype=="client"){
                        this.props.history.push('/admin/request/list');
                    }else{
                        this.props.history.push('/admin/request/assigned');
                    }
                }



            }else{
                Utilites.showErrorMessage(result.data.message);
                this.setState({})
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong login info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }




  render() {
        const {showverifiederror} = this.state
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="8" md="8">
                 <Card className="card-login" style={{marginTop:100,padding:30}}>
                    <CardBody>

                        <Row >
                            <Col md="6">
                                <h5 className={"bold"}>Sign in</h5>
                            </Col>
                            <Col md="6">
                                <Link to={"/auth/register"} className="pull-right" >Create a new account</Link>
                            </Col>
                        </Row>
                        {showverifiederror &&<Row >
                            <Col md="12">
                                 <div className={"errorx"}>  Your Account need verfication ,  <a href={"#"} onClick={()=>{
                                    this.resendVerfication();
                                }}> resend</a> verification link</div>
                            </Col>

                        </Row>}

                        <br />
                        <AvForm   className="form-horizontal" id="TypeValidation">
                            <Row>
                                <Col md="4">
                                    <Link to={"/auth/forgotpassword"} >Forgot Password?</Link>
                                </Col>

                                <Col md="4">

                                </Col>



                                <Col md="4" className={"pull-right"} style={{textAlign:"right"}}>

                                </Col>

                            </Row>
                        </AvForm>

                    </CardBody>

                            <div className={"centrino"}>
                                <GApp triggerlogin={this.triggerlogin.bind(this)} />
                            </div>
                </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"

        />
      </div>
    );
  }
}

export default Login;
