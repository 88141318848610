import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col, Button, Table
} from "reactstrap";


import { Stepper } from 'react-form-stepper';
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import SelectRow from "../../components/Select/SelectRow";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import {Utilites} from "../../core/Utilites";

class OfferSend extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            allowphysical:false,
            step:"0",
            method:"Wire Transfer",
            horizontalTabs:"paymenttype1",
            _id:"",
            loading : false,
            methodlist:["Wire Transfer","Physical Payment"],
            offers:[]
        };
        this.pathname = "offer";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        const userid = localStorage.getItem('userid');
        if(userid){
            this.setState({
                userid
            })
        }

        if (edit){
            this.setState({
                ...edit,
            });
        }

        this.callPage()
    }



    async updatePayment() {
        this.setState({
                isLoading: true,
            }
        );
        const {step,method} = this.state;
        debugger
        const offerid = this.props.match.params.id
        const data ={
            offerid:offerid,
            step:step,
            method:method
        }
        const response = await  this.engine.postItem(this.pathname,"/payment/update",data);

        if(response.status === 200 && response.data.success){
            debugger
            this.setState({
                    isLoading: false,
                ...response.data.data.data,
                    allowphysical:response.data.data.allowphysical
                },()=>{
                    if(!this.state.allowphysical){
                        this.setState({
                            methodlist:["Wire Transfer"]
                        })
                    }else{
                        this.setState({
                            methodlist:["Wire Transfer","Physical Payment"]
                        })
                    }
                }
            );
        }else{
            this.setState({
                isLoading: false,
                step:step-1
            })
            Utilites.showErrorMessage(response.data.message);
        }

        this.props.loadingAction(false);
    }
    async callPage() {
        this.setState({
                isLoading: true,
            }
        );

        const offerid = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/payment/"+offerid);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    ...response.data.data.data,
                    allowphysical:response.data.data.allowphysical
                },()=>{
                    if(!this.state.allowphysical){
                        this.setState({
                            methodlist:["Wire Transfer"]
                        })
                    }else{
                        this.setState({
                            methodlist:["Wire Transfer","Physical Payment"]
                        })
                    }
                }
            );
        }
        this.props.loadingAction(false);
    }





    async sendactivateoffer(){
        const offerid = this.props.match.params.id
        this.setState({
            isLoading:true
        })
        const response = await  this.engine.getItem(this.pathname,"/activate/"+offerid);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                },()=>{
                this.props.history.push("/admin/offer/pending");
                }
            );
        }
        this.props.loadingAction(false);
    }




    renderStep1() {

        return (
            <Card className={"p-3"}>


                <CardHeader>
                    <Row>
                        <Col md="12">

                            Dear Expert, please accept your Expert specific <a href={"https://food-sight.com/terms-and-conditions/expert-specific-terms-conditions-foodsight/"} target={"_blank"}>T&Cs</a> listing all the details you need to know about the project process, payment modalities & terms, and other important details you need to be reminded of.
                        </Col>

                    </Row>

                    <Row>
                        <Col md="12">

                            <Button className={"emptybtn"}  onClick={()=>{
                                this.props.history.goBack();
                            }} >Cancel</Button>

                            <Button  color="primary" onClick={()=>{
                                this.setState({
                                    step:1
                                },()=>{
                                    this.updatePayment();
                                })
                            }} >Accept</Button>

                        </Col>

                    </Row>



                </CardHeader>

            </Card>
        )

    }




    renderStep3() {

        return (
            <Card className={"p-3"}>


                <CardTitle tag="h4" style={{marginTop:10,marginLeft:10,fontWeight:"bold"}} className="pull-left" >Payment Method Selected Successfully  </CardTitle>

                <CardHeader>


                    <Row>
                        <Col md="12">

                            Be reminded of the payment option you selected under your profile page. If you wish to change it, please edit the details under your profile page. FoodSight team will make sure to coordinate with you upon project completion to submit the payment, if your offer was awarded to work on the project. Stay tuned to receive the update on your offer status.
                        </Col>

                    </Row>

                    <Row>
                        <Col md="12">

                            <Button  color="primary" onClick={()=>{
                                this.sendactivateoffer()
                            }} >Submit Offer</Button>

                        </Col>

                    </Row>



                </CardHeader>

            </Card>
        )

    }
    render() {
        // taking all the states
        const {isLoading,step }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <Stepper
                        steps={[{ label: 'Accept Terms & Conditions' },  { label: 'Create Offer' }]}
                        activeStep={step}
                        ConnectorStyleProps={{
                            activeColor:"#3E50B5"
                        }}
                        activeColor={"#3E50B5"}
                        completedColor={"#3E50B5"}
                    />
                    {step=="0" && this.renderStep1()}

                    {step=="1" && this.renderStep3()}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(OfferSend);

