import React from "react";

import Spotlight from 'react-spotlight';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "../../routes.js";
import Breadcrumbs from "./Breadcrumbs";

import Loading from 'react-loading-bar'
import 'react-loading-bar/dist/index.css'
import {connect} from "react-redux";

import CoreEngine from "../../core/CoreEngine";
import {resetMsg} from "../../core/redux/actions/showMsg";
import {Utilites} from "../../core/Utilites";
let ps;

class Admin extends CoreEngine {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      playing: false,
      x: 0,
      y: 0,
      radius: 75,
      color: '#ada9a9',
      borderColor: '#1BE6BD'
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    this.start = this.start.bind(this);


   // Utilites.setTimeout(()=>{
    //  this.start()
   // },2000)
  }



  start() {
    this.setStatePromise({ playing: true, ...this.getCoordinates('.btn-try') })
        .then(() => this.sleep(800))
        .then(() => this.setStatePromise({text:"sim", radius: 200, ...this.getCoordinates('.sidebar-wrapper') }))
        .then(() => this.sleep(2000))
        .then(() => this.setStatePromise({ text:"aaaa",radius: 100, ...this.getCoordinates('#navbarDropdownMenuLink') }))
        .then(() => this.sleep(1500))
        .then(() => this.setStatePromise({ text:"8888",radius: 100, ...this.getCoordinates('#linkbtn') }))
        .then(() => this.sleep(2000))
        .then(() => this.setStatePromise({ text:"zzzz",radius: 100, ...this.getCoordinates('.abdallahbadranext') }))
        .then(() => this.sleep(2500))
        .then(() => this.setStatePromise({ playing: false }));
  }

  /* The following are some convenience methods to make chaining the animation more convenient */

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  setStatePromise(state) {
    this.setState(state);
    return Promise.resolve();
  }

  getCoordinates(selector) {
    const domEl = document.querySelector(selector);
    if (!domEl) return {};

    const rect = document.querySelector(selector).getBoundingClientRect();
    return { x: rect.left + (rect.width / 2), y: rect.top + (rect.height / 2) };
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };





  render() {


    if(this.props.showmsg && this.props.showmsg.type !==0 ){
        this.showalert(this.props.showmsg.msg,this.props.showmsg.type)
    }

    return (
      <div className="wrapper">
        <div style={{
          position: "relative",
          display:"none",
          zIndex: 3333}}>
          <button onClick={this.start} className="btn btn-try">Try it out!</button>


          {this.state.playing && (
              <div key="1">
                <Spotlight
                    x={this.state.x}
                    y={this.state.y}
                    radius={this.state.radius}
                    color={this.state.color}
                    outerStyles={{ zIndex: 0 }}
                    responsive
                    animSpeed={1000}
                    borderColor={this.state.borderColor}
                    borderWidth={10}>
                  <div style={{
                    position: 'absolute',
                    left: '50%',
                    top: '-50px',
                    transform: 'translate(-50%, -100%)',
                    whiteSpace: 'nowrap'
                  }}>
                    <h1 style={{ margin: 0 }}>{this.state.text}</h1>
                  </div>
                </Spotlight>
              </div>
          )}

        </div>
          <Loading
              show={this.props.progress.loading}
              color="red"
          />


        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
            <div className="breadmin"><Breadcrumbs /></div>

          <Switch>{this.getRoutes(routes)}</Switch>
          {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )}
        </div>

      </div>
    );
  }
}


const mapStateToProps = state => ({

    ...state
});

const mapDispatchToProps = dispatch => ({
    resetMsg: () => dispatch(resetMsg()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
