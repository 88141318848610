import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col, Container, Button, Input
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";

import {Utilites} from "../../core/Utilites";
import SelectRow from "../../components/Select/SelectRow";
import {selectCountryList} from "../../core/countries";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";


class ResetPassword extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            email:"",
            loading:false,
        };
        this.engine = new RequestEngine();
    }





    async resetUser(){

        try {
            this.setState({loading : true})
            const result = await this.engine.postItem("user","/resetpassword",this.state)
            if(result.status === 200 && result.data.success){
                Utilites.showSucessMessage("Please Check your email")
            }else{
                Utilites.showErrorMessage(result.data.message);
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }






    render() {
        const {
            email,
            loading
        } = this.state


        return (
            <div className="content english">

                <Row>
                    <Col  lg="12" md="12">
                        <Card className="card-login" style={{marginTop:10,padding:30}}>
                            <AvForm   className="form-horizontal" id="TypeValidation">
                                <CardHeader>
                                    <CardTitle tag="h4">Reset Password</CardTitle>
                                </CardHeader>
                                <CardBody>

                                    <Row>

                                        <Col md="12" >
                                            <FormRow label="Your Email"  onKeyPressCapture={event => {
                                                if(event.charCode == 13){
                                                    this.resetUser()
                                                    //alert(33)
                                                }
                                            }} className={"mr-2"} name="email" type={"email"} data={email}  errorMessage={"Please write a valid email"}  changeInput={this.changeInput.bind(this)} />
                                        </Col>

                                    </Row>


                                </CardBody>






                                <CardFooter >

                                    <ButtonLoader onClick={() => this.resetUser()} className="pull-right" color="primary" loading={loading}>Reset</ButtonLoader>
                                </CardFooter>

                            </AvForm>

                        </Card>



                    </Col>
                </Row>


            </div>
        );

    }
}


const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ResetPassword);

