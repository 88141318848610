import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Button, Label, FormGroup
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import RequestMiniInformation from "../request/RequestMiniInformation";
import {Utilites} from "../../core/Utilites";

export default class ViewOffer extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showaward:false,
            title:"",
            comment:"",
            userid:"",
            status:"",
            user:"",
            _id:"",
            request:"",
            currency:"",
            milestonestitle:[],
            milestonesdescription:[],
            milestonesduration:[],
            milestonescost:[],
            loading : false,
            usertype : localStorage.getItem('usertype')
        };
        this.pathname = "offer";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        const userid = localStorage.getItem('userid');
        if(userid){
            this.setState({
                userid
            })
        }


        if (edit){
            this.setState({
                ...edit,
            });
        }

        this.callPage()
    }


    async callPage() {
        const id = this.props.match.params.id
        if(!id){
            return;
        }
        this.setState({
                isLoading: true,
            }
        );

        const response = await  this.engine.getItem(this.pathname,"/info/"+id);

        if(response.status === 200 && response.data.success){

            this.setState({
                    isLoading: false,
                    ...response.data.data,
                    request:response.data.data.request && response.data.data.request._id,
                }
            );

        }else{
            this.setState({loading:false,showverifiederror:false})
            Utilites.showErrorMessage(response.data.message);
            this.setState({
                isLoading: false,
            })
        }
    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })



        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    editRequest(data) {
        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+data.id,
            data
        });
    }



    async sendAwardRequest(offerid){
        this.props.history.push('/admin/request/reward/'+offerid);
    }
    render() {
        // taking all the states
        const {isLoading, title,
            comment,
            milestonestitle,
            milestonesdescription,
            milestonesduration,currency,
            milestonescost,user,userid,status,showaward,_id,usertype }= this.state;
        if (isLoading) {
            return this.renderProgress()
        }

        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    {this.state.request && <RequestMiniInformation requestid={this.state.request} requesthints={localStorage.getItem('usertype')=="expert"} />}
                                    {title && <CardHeader>
                                        <CardTitle tag="h4">Offer Details  {user && user.id.toString() && userid==user.id.toString() && status.includes("pend") && <Button   className="emptybtn pull-right" style={{fontSize:13}}  onClick={()=>{
                                            this.editRequest(this.state);
                                        }} >Edit Offer</Button>}</CardTitle>

                                    </CardHeader>}
                                    {title &&<CardBody>
                                        <FormRow label="title" viewonly={true} name="name" data={title}    />
                                        <FormRow label="currency" viewonly={true} name="currency" data={currency}    />
                                        <FormRow label="comment"  viewonly={true} type={"textarea"} name="comment" data={comment}    />

                                        <hr/>
                                        {milestonestitle.map((item,index)=>{

                                            return (<div key={index}>

                                                <Row><Label sm={2}><b>Milestone #</b></Label>
                                                    <Col sm={1} style={{padding:10}} >
                                                        <FormGroup >
                                                            {1+index}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <FormRow label="Milestone Title" viewonly={true} name={"milestonestitle"} data={item.value}   />
                                                <FormRow label="Milestone Description" viewonly={true} type={"textarea"} name={"milestonesdescription"} data={milestonesdescription[index].value}   />
                                                <Row>
                                                    <Col md="4" >
                                                        <FormRow label={"Cost "} viewonly={true}  name={"milestonescost"} data={milestonescost[index].value}    />
                                                    </Col>


                                                    <Col md="4" >
                                                        <FormRow label="Duration (Days)" viewonly={true}  name={"milestonesduration"} data={milestonesduration[index].value}     />
                                                    </Col>
                                                </Row>
                                                <hr/>

                                            </div>)

                                        })}



                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Back</Button> &nbsp;

                                        {usertype=="client" && showaward && <div className="pull-right floatbtn" style={{width:190,padding:10}}>

                                            <Button className={"emptybtn"} onClick={()=>{
                                                this.sendAwardRequest(_id);
                                            }} >Award</Button>
                                        </div>}

                                    </CardBody>}

                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


