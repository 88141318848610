import React       from 'react';
import PropTypes   from 'prop-types';

import {
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import { AvField,AvGroup } from 'availity-reactstrap-validation';
import LoadMore from "../../core/LoadMore";

const propTypes = {
    spinColor:      PropTypes.string,
    spinConfig:     PropTypes.object,
    spinAlignment:  PropTypes.string
};

function FormRow({
                     label       = '',
                     placeholder='',
                     type       = 'text',
                     name = "none" ,
                     className="",
                     onKeyPressCapture,
                     info="",
                     viewonly=false,
                     includeRow = true,
                     required = true,
                     hidelabel = false,
                     readonly = false,
                     errorMessage = "Required",
                     helpMessage = "",
                     labelW="2",
                     formW="7",
                     data       ,
                     changeInput,
                     style={width:130}

                 }) {



    if(hidelabel){

        if(viewonly){
           return <Col sm={formW} style={{    textAlign: "right",
                    padding: 10}}>
                    <FormGroup >
                        <b>{data}</b>
                    </FormGroup>

                </Col>
        }
       return  <AvField
               name={name}
               type={type} // text  or textarea
               value = {data}
               required = {false}
               readOnly = {readonly}
               errorMessage={errorMessage}
               placeholder={placeholder}
               helpMessage={helpMessage}
               onKeyPressCapture={e =>
                   onKeyPressCapture && onKeyPressCapture(e, name)
               }
               style={style}
               autoComplete="none"
               onChange={e =>
                   changeInput && changeInput(e, name)
               }
           />
    }



    let output = <>

            <AvGroup style={{    width: "100%"}} >
                <Label >{label} {required && <code>*</code>}</Label>
                <AvField
                    name={name}
                    type={type} // text  or textarea
                    value = {data}
                    required = {required}
                    readOnly = {readonly}
                    errorMessage={errorMessage}
                    placeholder={placeholder}
                    helpMessage={helpMessage}
                    onKeyPressCapture={e =>
                        onKeyPressCapture && onKeyPressCapture(e, name)
                    }
                    autoComplete="none"
                    onChange={e =>
                        changeInput && changeInput(e, name)
                    }
                />
                <div className={"infox"}>{info}</div>
            </AvGroup>





        </>
    if(viewonly){
        output = <><Label sm={labelW}><b>{label}</b></Label>
            <Col sm={formW} style={{
                padding: 10}}>
                <FormGroup >
                    {data && data.length>900 ? <LoadMore description={data} />: <div  dangerouslySetInnerHTML={{__html: data}}></div>}
                </FormGroup>

            </Col>

        </>
    }
    if(includeRow){
        return ( <Row className={className}>{output}</Row>);
    }
    return output;

}

FormRow.propTypes = propTypes;

export default FormRow;
