import React from "react";

// reactstrap misc
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import {Utilites} from "../../core/Utilites";

class ForgotPassword extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password:"",
            password2:"",
            managertype : "",
            username :"",
            token : "",
            loading : false,
            remember:1,
            shouldpick:false,
            init:true,
            showverifiederror:false,
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        // localStorage.setItem('managertype', "admin");
        document.body.classList.toggle("login-page");
    }
    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }



    async resendVerfication(){


        const {email,password} = this.state


        try {
            this.setState({loading : true})

            const result = await this.engine.resendlink(email,password)


            if(result.status === 200 && result.data.success){



            }else{
                this.setState({loading:false,showverifiederror:false})
                Utilites.showErrorMessage(result.data.message);
                this.setState({})
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong login info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }
    async resetUser(){

        try {
            const {email} = this.state;
            if(!Utilites.validateEmail(email)){
                Utilites.showErrorMessage("Email Required please ");
                return ;
            }
            this.setState({loading : true})
            const result = await this.engine.postItem("user","/resetpassword",this.state)
            if(result.status === 200 && result.data.success){
                Utilites.showSucessMessage("Please Check your email")
            }else{
                Utilites.showErrorMessage(result.data.message);
            }
        }catch (e) {
            Utilites.showErrorMessage("Wrong info  ")
            this.setState({loading:false})
        }

        this.setState({loading : false})
    }




    render() {
        const {email,password,loading,showverifiederror} = this.state
        return (
            <div className="login-page">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="8" md="8">
                            <Card className="card-login" style={{marginTop:100,padding:30}}>
                                <CardBody>

                                    <Row >
                                        <Col md="6">
                                            <h5 className={"bold"}>Forgot Password </h5>
                                        </Col>

                                    </Row>


                                    <hr/>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="nc-icon nc-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Email Address" errorMessage={"Please write a valid email"}  typeof={"email"} onKeyPressCapture={event => {
                                            if(event.charCode == 13){
                                                this.resetUser()
                                            }
                                        }} value={email} onChange={e =>
                                            this.changeInput(e, "email")
                                        } type="text" />
                                    </InputGroup>
                                    <div className={"infox"}>Please enter your email address </div>
                                    <br />



                                </CardBody>

                                <div className={"centrino"}>
                                    <ButtonLoader className=" mb-3" onClick={() => this.resetUser()}   style={{width: "95%",
                                        margin: "auto"}} color="primary" loading={loading}>Send</ButtonLoader>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"

                />
            </div>
        );
    }
}

export default ForgotPassword;
