import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col, Button, Table
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import SelectRow from "../../components/Select/SelectRow";
import loadingAction from "../../core/redux/actions/loadingAction";
import {connect} from "react-redux";
import {Utilites} from "../../core/Utilites";
import RequestMiniInformationAdv from "./RequestMiniInformationAdv";

 class ViewRequest extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            title:"",
            expertise:"",
            budget:"",
            duration:"",
            comments:"",
            currency:"",
            startdate:"",
            status:"",
            category:"",
            deadline:"",
            createdAt:"",
            awardedoffer:false,
            attachments_url:[],
            userid:"",
            user:"",
            _id:"",
            loading : false,
            allrequest:{},
            offers:[]
        };
        this.pathname = "request";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        const userid = localStorage.getItem('userid');
        if(userid){
            this.setState({
                userid
            })
        }

        if (edit){
            this.setState({
                ...edit,
            });
        }


        this.callPage()
    }

    async callPage() {
        this.setState({
                isLoading: true,
            }
        );

        const requestid = this.props.match.params.id
        const response = await  this.engine.getItem(this.pathname,"/info/"+requestid);

        if(response && response.status === 200){


            this.setState({
                    isLoading: false,
                    ...response.data.data.info,
                allrequest:response.data.data.info,
                offers:response.data.data.offers,
                }
            );
        }
        this.props.loadingAction(false);
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })



        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


     viewOffer(data) {
         if(data.status!="awarded"){
             data.showaward = true;
         }
         this.props.history.push({
             pathname:'/admin/offer/view/'+data.id,
             data
         });
     }

    editRequest(data) {
        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+data.id,
            data
        });
    }

     async sendAwardRequest(offerid){
         this.props.history.push('/admin/request/reward/'+offerid);
     }

    render() {
        // taking all the states
        const {isLoading, title,
            expertise,
            budget,
            duration,
            comments,
            awardedoffer,
            deadline,
            createdAt,
            startdate,
            currency,
            category,offers,user,userid,attachments_url,status,allrequest }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Request Details    {user==userid && status=="pending" && <Button   className="emptybtn pull-right" style={{fontSize:13}}  onClick={()=>{
                                            this.editRequest(this.state);
                                        }} >Edit Request</Button>}</CardTitle>

                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="title" viewonly={true} name="name" data={title}    />
                                        <FormRow label="expertise"  viewonly={true}  name="expertise"    data={expertise}  />
                                        <FormRow label={"Budget ("+currency+")"}  viewonly={true} type={"number"} name="budget" data={budget}     />
                                        <FormRow label="Duration (Days)"  viewonly={true} type={"number"} name="duration" data={duration}    />
                                        <FormRow label="Start Date"  viewonly={true}  name="startdate"   data={startdate}  />
                                        <FormRow label="End Date"  viewonly={true}  name="deadline"   data={deadline}  />
                                        <FormRow label="category"  viewonly={true}  name="category"   data={category}  />
                                        <FormRow label="comments"  viewonly={true} type={"textarea"} name="comments" data={comments}   />
                                        <FormRow label="Created date"  viewonly={true}  name="createdAt" data={this.renderDate(createdAt)}   />
                                        <RequestMiniInformationAdv data={allrequest} />
                                        <div>
                                            {attachments_url.map((i,index)=>{
                                                return <li key={index}><a target={"_blank"} href={i.url}>{i.name}</a> </li>
                                            })}
                                        </div>
                                    </CardBody>


                                    {offers && offers.length==0 ?  <CardHeader>
                                        <CardTitle tag="h4" className="pull-left">No Offer Found , Expect to receive offer(s) from expert(s) within 10 working days from this date</CardTitle>
                                        <br/>
                                    </CardHeader>:<CardBody>
                                        <div className="table-full-width table-responsive">
                                            <Table>
                                                <thead className="text-primary">
                                                <tr>
                                                    <th >Offer Title</th>
                                                    <th >Total Milestones</th>
                                                    <th >Total Cost</th>
                                                    <th>Total Duration (Days)</th>
                                                    <th>Status</th>
                                                    <th  ></th>
                                                    <th  ></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {offers && offers.map((item, index) => {
                                                    if(item.status=="retracted"){
                                                        return;
                                                    }
                                                    return (
                                                        <tr key={index}>
                                                            <td >
                                                                {item.title}
                                                            </td>
                                                            <td >
                                                                {item.totalmilestones}
                                                            </td>
                                                            <td >
                                                                {item.cost} {item.currency}
                                                            </td>
                                                            <td >
                                                                {item.duration}
                                                            </td>
                                                            <td >
                                                                {item.status}
                                                            </td>
                                                            <td className="td-actions ">
                                                                <div className="pull-right floatbtn" style={{width:190,padding:10}}>
                                                                    <Button className={"rawbtn"}  onClick={()=>{
                                                                        this.viewOffer(item);
                                                                    }} >View Offer</Button>

                                                                </div>
                                                            </td>
                                                            <td className="td-actions ">
                                                                {!awardedoffer && <div className="pull-right floatbtn" style={{width:190,padding:10}}>
                                                                    <Button className={"emptybtn"} onClick={()=>{
                                                                        this.sendAwardRequest(item._id);
                                                                    }} >Award</Button>
                                                                </div>}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>

                                        </div>
                                        <div>
                                            “ The offers are valid for 15 days, if not awarded within the set period, they might be subject to condition and price changes.”
                                        </div>
                                        <Button   onClick={()=>{
                                            this.props.history.goBack();
                                        }}> Back</Button> &nbsp;
                                    </CardBody>}




                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ViewRequest);

